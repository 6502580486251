import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {NavLink} from "react-router-dom";
import * as actions from "../actions";
import M from 'materialize-css';
import {Button, Icon, 
    // Navbar, 
    NavItem,
} from "react-materialize";

import Logo from './img/vidchops-transparent.png';
import Augie from './img/augie_thumbnail.png';
import HandbookIcon from './img/handbook_icon.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ADMIN, CUSTOMER, QA_USER, TEAM_LEAD, YT_HELPER, YT_HELPER_LEAD} from "../api/Constants";

import 'react-pro-sidebar/dist/css/styles.css';
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent,Menu,MenuItem } from 'react-pro-sidebar';
import { AiOutlineCloudUpload, AiOutlineRobot, AiOutlineUserSwitch, AiOutlineYoutube, AiOutlineDashboard, AiOutlineSetting } from  'react-icons/ai';
import { HiOutlineUserGroup } from  'react-icons/hi';
import { RiArchiveDrawerLine } from  'react-icons/ri';
import { BiHomeAlt, BiMessageAlt, BiStar, BiCard, BiExport} from 'react-icons/bi';
import { setActiveTab } from '../api/Util';
import { SELECTED_CARD_SECTION } from '../actions/types';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


export default connect(mapStateToProps, actions)((props) => {
    const auth = props.auth;
    const unSeenMessages = props.inboxSeenMessages?.unSeenCount;
    const dispatch = useDispatch();
    const {pathname} = useLocation()
    useEffect(() => {
        console.debug(M != null);
    }, []);

    const [toggled, setToggled] = useState(false);

    useEffect(() => {
        if (auth.loggedIn && !auth.loggedInUser && auth.loading === false) {
            props.fetchUser();
        }
        
        if (auth.loggedIn && !auth.loggedInUser){
            props.fetchAllWebsiteUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.loggedIn, auth.loggedInUser]);

    useEffect(() => {
        if(window.innerWidth<992)
        {
            setToggled(true);
            props.handleIsMobileView(true);
        }
        else
        {
            setToggled(false);
            props.handleIsMobileView(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.innerWidth])

    useEffect(() => {
        props.handleSidebarToggled(toggled)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggled]);

    function userTypeOptions(user) {
        let clientType = (user && user.client_type) || '';
        switch(clientType) {
            case ADMIN:
                return adminHeaderLinks();
            case TEAM_LEAD:
            case YT_HELPER_LEAD:
                return teamLeadHeaderLinks();
            case QA_USER:
            case YT_HELPER:
                return qaHeaderLinks();
            case CUSTOMER:
                return customerHeaderLinks();
            default:
                return '';
        }
    }

    const refreshCardHistory = () => {
        if (pathname !== "/") {
            dispatch({
                type: SELECTED_CARD_SECTION,
                payload: { status: 200, data: '' }
            });
        }
    };
    
    function adminHeaderLinks() {
        return [
            <MenuItem key="home" onClick={() => {setActiveTab("normal"); refreshCardHistory()}}>
                <NavLink to="/">
                    <BiHomeAlt/>&nbsp;&nbsp;Home
                </NavLink>
            </MenuItem>,
            <MenuItem key="uploadNav">
            <NavLink to="/upload">
            <AiOutlineCloudUpload/>&nbsp;&nbsp;Upload
                {/* <FontAwesomeIcon icon="user-tie"/> */}
            </NavLink>
            </MenuItem>,
            <MenuItem key="usersNav">
            <NavLink to="/users">
            <AiOutlineUserSwitch/>&nbsp;&nbsp;Users
                {/* <FontAwesomeIcon icon="user-tie"/> */}
            </NavLink>
            </MenuItem>,
            <MenuItem key="customersNav">
            <NavLink to="/customers">
                {/* <FontAwesomeIcon icon="users"/> */}
                <HiOutlineUserGroup/>&nbsp;&nbsp;Customers
            </NavLink>
            </MenuItem>,
            // <MenuItem key = "ytHelperNav">
            // <NavLink to = "/ythelper/dashboard" onClick={() => setActiveTab("yt-helper")}>
            //     {/* <FontAwesomeIcon icon = "user"/> */}
            //     <AiOutlineYoutube/>&nbsp;&nbsp;YouTube Helper
            // </NavLink>
            // </MenuItem>,
            <MenuItem key="clientHandbookNav">
            <NavLink to="/client-handbook">
                <img src={HandbookIcon} style={{ width: '33px', marginLeft: '-2px', verticalAlign: 'middle' }} alt="Client Handbook" />&nbsp;&nbsp;&nbsp;&nbsp;Client Handbook
            </NavLink>
            </MenuItem>,
            <MenuItem key="dashboardNav">
            <NavLink to="/dashboard">
                {/* <FontAwesomeIcon icon="chart-line"/> */}
                <AiOutlineDashboard/>&nbsp;&nbsp;Dashboard
            </NavLink>
            </MenuItem>,
            <MenuItem key="archivedNav">
            <NavLink to="/cards/archive">
                {/* <FontAwesomeIcon icon="archive"/> */}
                <RiArchiveDrawerLine/>&nbsp;&nbsp;Archived
            </NavLink>
            </MenuItem>,
            <MenuItem key="settingsNav">
            <NavLink to="/settings">
                {/* <FontAwesomeIcon icon="cogs"/> */}
                <AiOutlineSetting/>&nbsp;&nbsp;Portal Settings
            </NavLink>
            </MenuItem>,
            <MenuItem key="inbox">
                <NavLink to="/inbox">
                    <BiMessageAlt />&nbsp;&nbsp;Inbox&nbsp;
                    {
                        unSeenMessages > 0 &&
                        <span className="unseenMessagesCount">
                            {unSeenMessages}
                        </span>
                    }
                </NavLink>
            </MenuItem>,
            <MenuItem key="dueCards">
                <NavLink to="/dueCards">
                    <BiCard />&nbsp;&nbsp;Due Cards
                </NavLink>
            </MenuItem>,
              props.auth.loggedInUser.client_id === 10 ? 
               <MenuItem key="exportCards">
               <NavLink to="/exportCards">
                   <BiExport />&nbsp;&nbsp;Export Cards
               </NavLink>
           </MenuItem>: null,
            <MenuItem key="ratingNav">
                <NavLink to="/rating">
                    <BiStar />&nbsp;&nbsp;Rating
                </NavLink>
            </MenuItem>,
            <MenuItem key="AIToolbox">
                <NavLink to="/AIToolbox">
                    <AiOutlineRobot />&nbsp;&nbsp;AI Toolbox
                </NavLink>
            </MenuItem>,
        ];
    }

    function teamLeadHeaderLinks() {
        return [
            <MenuItem>
                <NavLink key="home" to="/" onClick={() => {setActiveTab("normal"); refreshCardHistory()}}>
                    <BiHomeAlt/>&nbsp;&nbsp;Home
                </NavLink>
            </MenuItem>,
            <MenuItem>
            <NavLink key="uploadNav" to="/upload">
                <Icon left style = {{ marginRight : "0px" }} >upload</Icon>Upload
            </NavLink>
            </MenuItem>,
            <MenuItem key="clientHandbookNav">
            <NavLink to="/client-handbook">
                <img src={HandbookIcon} style={{ width: '33px', marginLeft: '-2px', verticalAlign: 'middle' }} alt="Client Handbook" />&nbsp;&nbsp;&nbsp;&nbsp;Client Handbook
            </NavLink>
            </MenuItem>,
            // to dispaly yt helper dashboard only to yt helper team lead
            // props.auth.loggedInUser.client_type === YT_HELPER_LEAD ? 
            //     <MenuItem onClick={() => setActiveTab("yt-helper")}><NavLink key = "ytHelperNav" to = "/ythelper/dashboard">
            //         <FontAwesomeIcon icon = "user"/>&nbsp;&nbsp;YouTube Helper
            //     </NavLink> </MenuItem> : null,
            <MenuItem> <NavLink key="usersNav" to="/users">
                <FontAwesomeIcon icon="users"/>&nbsp;&nbsp;My Team
            </NavLink></MenuItem>,
            <MenuItem> <NavLink key="customersNav" to="/customers">
                <FontAwesomeIcon icon="users"/>&nbsp;&nbsp;My Customers
            </NavLink></MenuItem>,
            props.auth.loggedInUser.client_type === TEAM_LEAD ? 
                <MenuItem key="dashboardNav">
                    <NavLink to="/dashboard">
                        {/* <FontAwesomeIcon icon="chart-line"/> */}
                        <AiOutlineDashboard/>&nbsp;&nbsp;Dashboard
                    </NavLink>
                </MenuItem> : null,
            <MenuItem key="inbox">
                <NavLink to="/inbox">
                    <BiMessageAlt />&nbsp;&nbsp;Inbox&nbsp;
                    {
                        unSeenMessages > 0 &&
                        <span className="unseenMessagesCount">
                            {unSeenMessages}
                        </span>
                    }
                </NavLink>
            </MenuItem>,
            <MenuItem key="archivedNav">
                <NavLink to="/cards/archive">
                    {/* <FontAwesomeIcon icon="archive"/> */}
                    <RiArchiveDrawerLine/>&nbsp;&nbsp;Archived
                </NavLink>
            </MenuItem>,
            <MenuItem key="dueCards">
                <NavLink to="/dueCards">
                    <BiStar />&nbsp;&nbsp;Due Cards
                </NavLink>
            </MenuItem>,
        ];
    }

    function qaHeaderLinks() {
        return [
            <MenuItem onClick={() => {setActiveTab("normal"); refreshCardHistory()}}>
                <NavLink key="home" to="/">
                    <BiHomeAlt/>&nbsp;&nbsp;Home
                </NavLink>
            </MenuItem>,
            <MenuItem>
                <NavLink key="uploadNav" to="/upload">
                    <Icon left style = {{ marginRight : "0px" }} >upload</Icon>Upload
                </NavLink>
            </MenuItem>,
            <MenuItem key="clientHandbookNav">
            <NavLink to="/client-handbook">
                <img src={HandbookIcon} style={{ width: '33px', marginLeft: '-2px', verticalAlign: 'middle' }} alt="Client Handbook" />&nbsp;&nbsp;&nbsp;&nbsp;Client Handbook
            </NavLink>
            </MenuItem>,
            // display yt helper assigned card tab only for yt helper, not for qa login
            // props.auth.loggedInUser.client_type === YT_HELPER ? 
            //     <MenuItem onClick={() => setActiveTab("yt-helper")}><NavLink key = "ytHelperNav" to = "/ythelper/dashboard">
            //         <FontAwesomeIcon icon = "user"/>&nbsp;&nbsp;YouTube Helper
            //     </NavLink></MenuItem> : null,
            <MenuItem> <NavLink key="customersNav" to="/customers">
                <FontAwesomeIcon icon="users"/>&nbsp;&nbsp;My Customers
            </NavLink>
            </MenuItem>,
            <MenuItem key="dueCards">
                <NavLink to="/dueCards">
                    <BiStar />&nbsp;&nbsp;Due Cards
                </NavLink>
            </MenuItem>,
        ];
    }

    function customerHeaderLinks() {
        return [
            <MenuItem key="home" onClick={() => {setActiveTab("normal"); refreshCardHistory()}}>
                <NavLink to="/">
                    <BiHomeAlt/>&nbsp;&nbsp;Home
                </NavLink>
            </MenuItem>,
            <MenuItem key="clientHandbookNav">
            <NavLink to="/client-handbook">
                <img src={HandbookIcon} style={{ width: '33px', marginLeft: '-2px', verticalAlign: 'middle' }} alt="Client Handbook" />&nbsp;&nbsp;&nbsp;&nbsp;Client Handbook
            </NavLink>
            </MenuItem>,
            <MenuItem key="dashboardNav">
                <NavLink to="/dashboard">
                    <AiOutlineDashboard/>&nbsp;&nbsp;Dashboard
                </NavLink>
            </MenuItem>,
            // if customer have assigned yt helper then only show yt helper assigned card tab
            // props.auth.loggedInUser.has_youtube_helper ?
            //     (<MenuItem key = "ytHelperNav" onClick={() => setActiveTab("yt-helper")}><NavLink to = "/ythelper/dashboard">
            //         <FontAwesomeIcon icon = "user"/>&nbsp;&nbsp;YouTube Helper
            //     </NavLink> </MenuItem>):null,
            <MenuItem key="supportNav"><NavItem href="https://vidchops.activehosted.com/f/16">
                <FontAwesomeIcon icon="ticket-alt"/>&nbsp;&nbsp;Create Support Ticket
            </NavItem>
            </MenuItem>,
             <MenuItem key="inbox">
                <NavLink to="/inbox">
                    <BiMessageAlt />&nbsp;&nbsp;Inbox
                </NavLink>
            </MenuItem>,
            <MenuItem key="AIToolbox">
                <NavLink to="/AIToolbox">
                    <AiOutlineRobot />&nbsp;&nbsp;AI Toolbox
                </NavLink>
            </MenuItem>,
        ];
    }
    
    return auth?.loggedIn && <ProSidebar 
        collapsed = {toggled}
    >
    
    <SidebarHeader>
        <a className="brand-logo" href={"/"}>
            <img src={Augie} alt="Augie" className="avatar hide-on-small-only"/>
            <img src={Logo} alt="Vidchops" className="vidchops-logo"/>
        </a>
        <Button className="sidebar-toggle" onClick={()=>{
            if(toggled)
            {
                setToggled(false);
            }
            else
            {
                setToggled(true);
            }
        }} ><span className="bars first"></span>
            <span className="bars second"></span>
            <span className="bars third"></span>
        </Button>
    </SidebarHeader>
    <SidebarContent>
    <Menu iconShape="square">
        { userTypeOptions(auth?.loggedInUser) }
    </Menu>
    </SidebarContent>
    <SidebarFooter>
      {/**
       *  You can add a footer for the sidebar ex: copyright
       */}
    </SidebarFooter>
  </ProSidebar>;
   
});

function mapStateToProps({auth, inboxSeenMessages}) {
    return {auth, inboxSeenMessages};
}
