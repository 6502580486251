import React from 'react';
import { Modal, Typography } from 'antd';
import styles from './MobileModal.module.scss';

const MobileModal = ({ mobileModalData, clearFields, handleCancel, type }) => {
    return (
        <Modal title={mobileModalData.title}
            open={mobileModalData.isModalOpen}
            onCancel={handleCancel}
            afterClose={() => clearFields()}
            okButtonProps={{ style: { display: 'none' } }}>
                <div>
                    <Typography.Text className={styles.greyFontColor}>
                       {type === "link" ? <a target="_blank" rel="noopener noreferrer" className={styles.anchorTag} href={mobileModalData.modalContent}>{mobileModalData.modalContent}</a>
                        : mobileModalData.modalContent || "No Data Found"}
                    </Typography.Text>
                </div>
        </Modal>
    )
}

export default MobileModal;
