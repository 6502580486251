import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import styles from './VideoCommentsTabStyle.module.scss';
import './VideoCommentsTabStyle.scss';

import DetailsHeader from '../DetailsTabHeader/DetailsHeader';
import CardVideo from './CardVideo/CardVideo';

const { TabPane } = Tabs;

const INITIAL_STATE = {
	card: {
		card_title: "Card Details"
	},
	videoLink: "",
	videoVersions: {},
	videoVersionsCount: 0,
};

const VideoComments = ({ card, cardId, currentVideoUrl, setCurrentVideoUrl, fetchCardDetails }) => {
	const [tabsIncluded, setTabsIncluded] = useState(false);
	const [state, setState] = useState(INITIAL_STATE);
	const [activeTabKey, setActiveTabKey] = useState("repurposeDoneVideo"); // Default to "Done Video"
	const [cardData, setCardData] = useState({});


	const isTabsIncluded = (card) => {
			// setCardData(card);
		if (card?.repurpose_done_videos.length > 0) {
			setTabsIncluded(true);
		} else {
			setTabsIncluded(false);
			setActiveTabKey('repurposeDoneVideo'); // Default to "Done Video"
		}
	};

	useEffect(() => {
		isTabsIncluded(card);
	}, [card]);

	useEffect(() => {
		if (activeTabKey === "doneVideo") {
			setCardData((prev) => ({ ...prev, repurpose_video_versions: [], repurpose_done_videos: [], repurpose_done_link: "" }));
		}
		if (activeTabKey === "repurposeDoneVideo") {
			setCardData((prev) => ({ ...prev, repurpose_video_versions: card?.repurpose_video_versions, repurpose_done_videos: card?.repurpose_done_videos, repurpose_done_link: card?.repurpose_done_link }));
		}
	}, [activeTabKey]);

	useEffect(() =>{
    setCardData(card);
	},[card]);


	return (
		<div className={styles.container}>
			<DetailsHeader
				fetchCardDetails={fetchCardDetails}
				card={card}
			/>
			{tabsIncluded ? (
				<Tabs className='cardVideoType' defaultActiveKey={activeTabKey} onChange={(key) => setActiveTabKey(key)}>
					<TabPane tab="Done Videos" key="doneVideo">
						<CardVideo
							card={cardData}
							cardId={cardId}
							currentVideoUrl={currentVideoUrl}
							setCurrentVideoUrl={setCurrentVideoUrl}
							state={state}
							setState={setState}
							allowNewChat={(false)}
						/>
					</TabPane>
					<TabPane tab="Repurpose Videos" key="repurposeDoneVideo">
						<CardVideo
							card={cardData}
							cardId={cardId}
							currentVideoUrl={currentVideoUrl}
							setCurrentVideoUrl={setCurrentVideoUrl}
							state={state}
							setState={setState}
							allowNewChat={(true)}
						/>
					</TabPane>
				</Tabs>
			) : (
				<CardVideo
					card={card}
					cardId={cardId}
					currentVideoUrl={currentVideoUrl}
					setCurrentVideoUrl={setCurrentVideoUrl}
					state={state}
					setState={setState}
					allowNewChat={(true)}
				/>
			)}
		</div>
	);
};

export default VideoComments;
