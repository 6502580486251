import {Card, CardTitle, Col, Container, Row} from "react-materialize";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    DueDateLegend,
} from "./legends";
import {axiosClient} from "../api/httpClient";
import {convertServerToLocalTime, getUserShortName, isAdmin, isCustomer, isTerminal, isTerminalYtCard, parseSqlDateTime} from "../api/Util";
import {CANCELED, DONE, ON_HOLD, VIDEO_REQUEST, YT_HELPER_IN_DONE, YT_HELPER_IN_PROGRESS, YT_HELPER_SECTION} from "../api/Constants";
import Timer from "react-compound-timer";
import {connect} from "react-redux";
import moment from 'moment';

import {RiStackFill} from 'react-icons/ri';
import { VscComment } from  'react-icons/vsc';
import { AiOutlineTag } from  'react-icons/ai';
import { AiOutlinePlus } from  'react-icons/ai';
import { BiHourglass } from  'react-icons/bi';
import { BiTime } from  'react-icons/bi';
import { BsCheckAll, BsPerson } from  'react-icons/bs';
import { CommentOutlined } from "@ant-design/icons";
import { addEventsForCard } from "../services/cardsService";
import { AiOutlineCheck } from 'react-icons/ai';


function getDueDateRow(card, admin) {
    if (card.card_status === DONE || card.card_status === VIDEO_REQUEST || card.paused) {
        return "";
    }
    let now = new Date().getTime();
    let expiredTime = parseSqlDateTime(convertServerToLocalTime(card.due_date)).getTime() - now;
    let expired = (now - parseSqlDateTime(convertServerToLocalTime(card.due_date)).getTime()) > 0;

    return [
        <Row className="request-card-line" key="timer-row">
            {admin &&
            <Col s={6}>
                <BiHourglass/>&nbsp;
                {
                    expired ?
                        <span className="commonClassForPrimaryColor">Expired</span>:
                        <Timer initialTime={expiredTime}>
                            <Timer.Days /> days&nbsp;
                            <Timer.Hours formatValue={val => `${`${val}`.padStart(2, "0")}:`}/>
                            <Timer.Minutes formatValue={val => `${val}`.padStart(2, "0")}/>
                        </Timer>
                }
            </Col>
            }
        </Row>,
        (card.due_date) &&
        <Row className="request-card-line" key="due-date-row">
            <Col m={12}>
                <AiOutlinePlus/>&nbsp;{moment(card.due_date).format('lll')}
            </Col>
        </Row>,
    ];
}

function getYTCardDueDateRow(card, customer) {
    if (card.ytHelper_card_status === YT_HELPER_IN_DONE || card.ytHelper_card_status == null ) {
        return "";
    }
    let now = new Date().getTime();
    let elapsedTime = now - parseSqlDateTime(convertServerToLocalTime(card.yt_assigned_time)).getTime();
    let expired = (now - parseSqlDateTime(convertServerToLocalTime(card.yt_due_date )).getTime()) > 0;

    return [
        (card.yt_assigned_time) &&
        <Row className="request-card-line" key="timer-row">
            {
                !customer ? <Col s={6}>
                    <BiTime/>&nbsp;
                    {
                        expired ?
                            <span className="commonClassForPrimaryColor">Expired</span>:
                            <Timer initialTime={elapsedTime}>
                                <Timer.Days /> days&nbsp;
                                <Timer.Hours formatValue={val => `${`${val}`.padStart(2, "0")}:`}/>
                                <Timer.Minutes formatValue={val => `${val}`.padStart(2, "0")}/>
                            </Timer>
                    }
                </Col> : null
            }
            {   card.yt_timeline &&
                <Col s={6}>
                    <BiHourglass/>&nbsp;{card.yt_timeline} Hrs
                </Col>
            }
        </Row>,
        (card.yt_due_date) &&
        <Row className="request-card-line" key="due-date-row">
            <Col m={12}>
                <DueDateLegend/>&nbsp;{card.yt_due_date}
            </Col>
        </Row>,
    ];
}


export default connect(mapStateToProps)(({setCardActivityModal, setCardActivityData, card, viewCard, auth,section = "", setCardRepurposeModal, setCurrentCardId, currentCardRepurpose}) => {
    let ratingStar = "";
    let completeIcon = "";
    let pausedIcon = "";
    let revisionIcon = "";
    let repurposeIcon ="";
    let repurposeConpleteIcon = "";
    let revisionRepurposeIcon = "";
    let repurposeRatingStar = "";
    let doneCardRatingComment;
    let repurposeDoneCardRatingComment;
    const customer = isCustomer(auth.loggedInUser);
    const admin = isAdmin(auth.loggedInUser);
    if(section !== YT_HELPER_SECTION)
    {
        if (card.card_status === DONE) {
            let rating = card.doneCardRating ?
                (<strong className="fa-layers-text">{card.doneCardRating}</strong>) :
                (<FontAwesomeIcon icon="question" color="black" size="xs"/>);

            ratingStar = (
                <span className="fa-layers fa-fw fa-pull-right">
                    <FontAwesomeIcon icon="star" color="#FFD700"/>
                    {rating}
                </span>
            )
    
            if (card.is_complete) {
                completeIcon = (
                    <FontAwesomeIcon icon="check" color="green" pull="right"/>
                );
            }
            doneCardRatingComment = card?.doneCardRatingComment ? (
                    <Col s={6} m={12} l={10} className="ratingCommentSection-inCardList">
                        <VscComment/>&nbsp;{card?.doneCardRatingComment}
                    </Col>
            ) : null;

            if (card.is_repurpose_complete) {
                let repurposeRating = card.repurposeCardRating ?
                    (<strong className="fa-layers-text">{card.repurposeCardRating}</strong>) :
                    (<FontAwesomeIcon icon="question" color="black" size="xs"/>);

                repurposeRatingStar = (
                    <span className="fa-layers fa-fw fa-pull-right">
                        <FontAwesomeIcon icon="star" color="#FFD700"/>
                        {repurposeRating}
                    </span>
                )

                repurposeConpleteIcon = (
                    <BsCheckAll style={{ float:'right',color:'green', strokeWidth: '1px', width: '27px', height: '27px' }} />
                );

                repurposeDoneCardRatingComment = card?.repurposeDoneCardRatingComment ? (
                        <Col s={6} m={12} l={10} className="ratingCommentSection-inCardList">
                            <VscComment/>&nbsp;{card?.repurposeDoneCardRatingComment}
                        </Col>
                ) : null;
            }


        }

        if(card.repurpose_revisions > 0 && card.card_status === 'done' && (card.repurpose_status=== 'done' || card.repurpose_status === 'editing')){
            revisionRepurposeIcon = (<FontAwesomeIcon icon="redo" color="purple" pull="right"/>)
        }

        if(card.revision_nr > 0 && !isTerminal(card.card_status)) {
            revisionIcon = (<FontAwesomeIcon icon="redo" className="commonClassForPrimaryColor" pull="right"/>)
        }
    
        if(card.paused && !isTerminal(card.card_status)) {
            pausedIcon = (<FontAwesomeIcon icon="pause-circle" color="blue" pull="right"/>)
        }
        if(card.repurpose_aspect || card.repurpose_headline)
        {
            repurposeIcon = (<RiStackFill style={{ float : "right" }} className="commonClassForPrimaryColor" />);
        }
    }
    else
    {
        if (section === YT_HELPER_SECTION) {
            let rating = card?.doneCardRating ? 
            (<strong className="fa-layers-text">{card.doneCardRating}</strong>) : 
            (card?.rating ? 
                (<strong className="fa-layers-text">{card.rating}</strong>) : 
                (<FontAwesomeIcon icon="question" color="black" size="xs"/>)
            );
        
            ratingStar = (
                <span className="fa-layers fa-fw fa-pull-right">
                    <FontAwesomeIcon icon="star" color="#FFD700"/>
                    {rating}
                </span>
            );
        
            if (card.yt_is_complete) {
                completeIcon = (
                    <FontAwesomeIcon icon="check" color="green" pull="right"/>
                );
            }
            if (card.is_complete && !card.yt_is_complete) {
                completeIcon = (
                    <FontAwesomeIcon icon="check" color="green" pull="right"/>
                );
            }
        }

        

        if(card.yt_card_revision > 0 && !isTerminalYtCard(card.ytHelper_card_status)) {
            revisionIcon = (<FontAwesomeIcon icon="redo" className="commonClassForPrimaryColor" pull="right"/>)
        }
    
        // paused functionality is not there for yt helper card
        // if(card.paused && !isTerminalYtCard(card.ytHelper_card_status)) {
        //     pausedIcon = (<FontAwesomeIcon icon="pause-circle" color="blue" pull="right"/>)
        // }
    }

    return (
        <Card className="request-card" onClick={ () => {
            addEventsForCard('card_open', card.card_id);
            viewCard(card.card_id);
            const cmtId = "comment-outlined-" + card.card_id;
            if ((card.unseenComments === 0) && (card?.qa_assigned_to ===  auth?.loggedInUser?.client_id)) {
                document.getElementById(cmtId).style.display = "none";
            }
            if ((card.unseenComments_ytHelper === 0) && (card?.yt_helper_id ===  auth?.loggedInUser?.client_id)) {
                document.getElementById(cmtId).style.display = "none";
            }
        }}
              header={<CardTitle className="request-card-title" image="" onClick={() => viewCard(card.card_id)}>
                  <p style={{display: "inherit"}}>{card.card_title}</p>
                    <div style={{ display: "flex", gap: "5px" }}>
                        {ratingStar}
                        {completeIcon}
                        {repurposeRatingStar}
                        {repurposeConpleteIcon}
                        {revisionIcon}
                        {revisionRepurposeIcon}
                        {pausedIcon}
                        {repurposeIcon}
                        {card.unseenComments === 0 && <CommentOutlined id={`comment-outlined-${card.card_id}`} style={{ color: "green"}} />}
                        {card.unseenComments_ytHelper === 0 && <CommentOutlined id={`comment-outlined-${card.card_id}`} style={{ color: "red"}} />}
                    </div>
              </CardTitle>}
        >
            <Container>
                <Row className="request-card-line">
                    <Col s={6} m={12} l={6}>
                       <BsPerson/>
                        &nbsp;<strong>{card.firstname || card.lastname ? `${card.firstname || ''} ${card.lastname || ''}` : 'N/A'}</strong>
                    </Col>
                    <Col s={6} m={12} l={6}>
                        <AiOutlinePlus/>&nbsp;{card.creation_time}
                    </Col>
                    <Col s={6} m={12} l={6}>
                       <BsPerson/>
                       &nbsp; <strong>{card.qa_firstname || card.qa_lastname ? `${getUserShortName(card.qa_firstname || '', card.qa_lastname || '')}` : 'N/A'}</strong>
                    </Col>
                </Row>
                {
                    !customer ?
                        <Row className="request-card-line">
                            <Col s={6} m={12} l={6}>
                            <AiOutlineTag/>&nbsp;<strong>{card.subscription_type}</strong>
                            </Col>
                        </Row> : null
                }
                    {doneCardRatingComment}
                    {repurposeDoneCardRatingComment}
                {
                    card.yt_helper_id   &&
                    <Row className="request-card-line">
                        <Col s={6} m={12} l={10}>
                        <AiOutlineTag/>&nbsp;<strong>{"Yt Helper Status: " + (card?.ytHelper_card_status ? 
                                (card?.ytHelper_card_status === YT_HELPER_IN_PROGRESS ? "Progress" 
                                : (card?.ytHelper_card_status === "inDone" && card?.yt_is_complete === 1 
                                ? "Completed" : "Review")) : "ASSIGNED CARD")}</strong>
                        </Col>
                    </Row>
                }
                { section === YT_HELPER_SECTION ? getYTCardDueDateRow(card, customer) : getDueDateRow(card, admin)}
                {
                    section === YT_HELPER_SECTION ? (card.yt_comments ?
                        <Row>
                            <Col m={3}>
                            <VscComment/>&nbsp;{card.yt_comments}
                            </Col>
                        </Row> : "") 
                    :
                    (card.comments ?
                        <Row>
                            <Col m={3}>
                            <VscComment/>&nbsp;{card.comments}
                            </Col>
                        </Row> : "")
                }
                    <Row className="card-activity-btn">
                {!customer ?
                        <Col s={6} m={10} l={6}>
                            <button className="activity-status-btn" onClick={async (event) => {
                                setCardActivityData([]);
                                event.stopPropagation();
                                event.preventDefault();
                                setCardActivityModal(true);
                                const data = await axiosClient.get('/api/card-activity/' + card.card_id);
                                setCardActivityData(data.data);
                            }}>
                                <strong>Activity Status</strong>
                            </button>
                        </Col>: null
                    }
                 { !card.repurpose_aspect && currentCardRepurpose && (customer || admin) ?
                        <Col s={6} m={10} l={6}>
                            <button className="activity-status-btn"onClick={async (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setCardRepurposeModal(true);
                                setCurrentCardId(card.card_id)
                            }}>
                                <strong>{<AiOutlineCheck />}Repurpose</strong>
                            </button>
                        </Col>:null
                      }
                    </Row> 
            </Container>
        </Card>
    );
});

function mapStateToProps({auth}) {
    return {auth};
}
