import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import Ratings from "react-ratings-declarative";
import { disableColor, primaryColor } from '../../../api/Util';
import { TextInput } from 'react-materialize';
import { markCardAsCompleted } from '../../../api/CardActions';

const RatingModal = ({ isRatingsModalOpen, handleCancelRatings, data }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const handleOkRatings = () => {
    markCardAsCompleted(data?.cardId, rating, comment, data?.qaAssignedTo);
    handleCancelRatings(); // Close modal after marking card as completed
  };

  return (
    <Modal
      className={'modalStyle'}
      title="Feedback"
      visible={isRatingsModalOpen}
      onCancel={handleCancelRatings}
      footer={[
        <Button key="back" onClick={handleCancelRatings}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={rating === 0}
          onClick={handleOkRatings}
        >
          OK
        </Button>
      ]}
    >
      <Ratings
        rating={rating}
        widgetHoverColors={primaryColor}
        widgetEmptyColors={disableColor}
        widgetRatedColors={primaryColor}
        changeRating={setRating}
      >
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
      </Ratings>
      <TextInput
        s={12}
        label="Any feedback?"
        icon="short_text"
        value={comment}
        onChange={e => setComment(e.target.value)}
      />
    </Modal>
  );
};

export default RatingModal;
