import React, { useState } from 'react';
import { DatePicker, Input, Drawer, Button, Select } from 'antd';
import styles from './leaveManagmentStyle.module.scss';
import { updateUserLeave } from '../../services/LeaveManagementService';
import { errorToast, infoToast, successToast } from '../../api/Util';
import dayjs from 'dayjs';

const { TextArea } = Input;
const { Option } = Select;


const LeaveManagement = ({ userId, getLeaveData, setActiveTab, setLeaveDataLoaded, userData }) => {
    const initialLeaveState = {
        userId: userId,
        start: dayjs(new Date()).format('YYYY-MM-DD'),
        end: dayjs(new Date()).format('YYYY-MM-DD'),
        reason: '',
        notifyOptions:[],
        userName: `${userData?.firstname} ${userData?.lastname}`,
        userClientType: userData?.client_type
    };
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [leaveData, setLeaveData] = useState(initialLeaveState);

    const handleDateChange = (dateType, newDate) => {
        setLeaveData({ ...leaveData, [dateType]: dayjs(newDate).format('YYYY-MM-DD') });
    };

    const handleCommentChange = (e) => {
        setLeaveData({ ...leaveData, reason: e.target.value });
    };

    const handleNotifyChange = (values) => {
        setLeaveData({ ...leaveData, notifyOptions: values });
    };

    const onCloseDrawer = () => {
        setDrawerVisible(false);
    };
    const disabledDate = (current) => {
        return current && current < dayjs().startOf('day');
    };

    const handleSubmit = async () => {
        infoToast("Loading....")
        setDrawerVisible(false);
        if (leaveData.start <= leaveData.end) {
            setLeaveDataLoaded(false)
            try {
                const result = await updateUserLeave(userId, leaveData);
                if (result.status) {
                    setLeaveData(initialLeaveState);
                    setActiveTab("LEAVE MANAGMENT")
                    getLeaveData();
                    successToast('Leave updated successfully!');
                } else {
                    errorToast(result.message);
                }
            } catch (error) {
                console.error('Error updating leave:', error);
            } 
        } else {  
            errorToast("Start date must be before end date");
          }
    };

    return (
        <>
            <Button className={styles.add_leave_button} onClick={() => setDrawerVisible(true)}>Add Leave</Button>
            <Drawer
                title="Leave Management"
                placement="right"
                closable={true}
                onClose={onCloseDrawer}
                className={styles.leave_drawer}
                footer={[
                    <Button className={styles.drawer_footer_button} onClick={onCloseDrawer}>Cancel</Button>,
                    <Button className={styles.drawer_footer_button} disabled={!leaveData.reason} onClick={handleSubmit}>Submit</Button>
                ]}
                open={drawerVisible}
            >
                <div className={styles.leave_form_container}>
                    <div className={styles.leave_input}>
                        <DatePicker
                            className={styles.date_picker}
                            placeholder='From Date'
                            defaultValue={dayjs(leaveData?.start)}
                            format={'YYYY-MM-DD'}
                            disabledDate={disabledDate}
                            onChange={(date) => handleDateChange('start', date)}
                        />
                    </div>
                    <div className={styles.leave_input}>
                        <DatePicker
                            className={styles.date_picker}
                            placeholder='To Date'
                            format={'YYYY-MM-DD'}
                            disabledDate={disabledDate}
                            defaultValue={dayjs(leaveData?.end)}
                            onChange={(date) => handleDateChange('end', date)}
                        />
                    </div>
                    <div className={styles.leave_input}>
                        <TextArea
                            rows={4}
                            placeholder="reason"
                            value={leaveData.reason}
                            onChange={handleCommentChange}
                        />
                    </div>
                    <div className={styles.leave_input}>
                    <Select
                        mode="multiple"
                        placeholder="Notify"
                        value={leaveData.notifyOptions}
                        onChange={handleNotifyChange}
                        className={styles.notify_dropdown}
                    >
                        {userData?.client_type === 'qa' &&
                            <>
                                <Option value={userData.client_id}>user</Option>
                                <Option value={userData?.assigned_to_lead_id}>Team Lead</Option>
                            </>
                        }
                        {userData?.client_type === "team-lead" &&
                            <Option value={userData.client_id}>user</Option>
                        }
                    </Select>

                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default LeaveManagement;
