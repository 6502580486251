import React, { useEffect, useRef, useState } from 'react';
import styles from '../VideoCommentsTabStyle.module.scss';
import '../VideoCommentsTabStyle.scss';
import { Select as MaterialSelect } from 'react-materialize';
import { useSelector } from 'react-redux';
import ChatBox from '../ChatComponent/ChatBox';
import { CUSTOMER, QA, QA_USER, YT_HELPER_SECTION } from '../../../../../api/Constants';
import { isMobile, parseSqlDateTime } from '../../../../../api/Util';
import { Typography, Row, Col, Empty } from 'antd';

const CardVideo = ({ card, cardId, currentVideoUrl, setCurrentVideoUrl, state, setState, allowNewChat}) => {
	let section = "";
	const [fetchChatForId, setFetchChatForId] = useState(null);
	const [showVideoLink, setShowVideoLink] = useState();
	const [isGDVideo, setIsGDVideo] = useState(false);
	const [showLargeChat, setShowLargeChat] = useState(false);
	const [videoVersionsState, setVideoVersionsState] = useState({ currentVersion: null, options: [] });
	const [showVideoDetail, setShowVideoDetail] = useState(true);
	const props = useSelector((state) => state)
	const loggedInUser = props.auth?.loggedInUser;
	const viewType = localStorage.getItem("viewType");

	const GD_LINK = /http(s)?:\/\/drive\.google\.com\/file\/d\/([^\\]+)\/.*/i;
	const isYtSection = viewType;
	const videoRef = useRef(null);
	const [seeking, setSeeking] = useState(false);
	function parseVideoLink(link) {
		const DBX_LINK = /http(s)?:\/\/(www.)?dropbox.com(.)+/i;

		if (DBX_LINK.test(link)) {
			return link.replace('dl=0', 'raw=1');
		}
		if ((link).search("vidchops-videos.s3-accelerate.amazonaws.com") !== -1) {
			return link;
		}
		if ((link).search('digitaloceanspaces.com') !== -1) {
			return link;
		}

		return null;
	}

	useEffect(() => {
			// if (loggedInUser.client_type === CUSTOMER && card?.card_status === QA) {
			// 	setShowLargeChat(true);
			// 	setShowVideoDetail(false);
			// }
		let videoVersionsCount = 0;
		let videoVersions = [];

		if (card?.repurpose_video_versions && Object.keys(card?.repurpose_video_versions).length > 0) {
			videoVersions = card?.repurpose_video_versions;
			videoVersionsCount = Object.keys(card?.repurpose_video_versions).length;
		} else if (card?.repurpose_status && card?.repurpose_done_link && card?.repurpose_done_link !== "") {
			if (card?.done_videos && card?.done_video_count > 0) {
				setFetchChatForId(card?.done_videos[0].video_id);
			}
			setCurrentVideoLink(card?.repurpose_done_link);

		} else if (card?.video_versions && Object.keys(card?.video_versions).length > 0) {
			videoVersions = card?.video_versions;
			videoVersionsCount = Object.keys(card?.video_versions).length;
		}

		setState({
			card: card,
			dueDate: section === YT_HELPER_SECTION ? (card?.yt_due_date ? parseSqlDateTime(card?.yt_due_date) : null) : (card?.due_date ? parseSqlDateTime(card?.due_date) : null),
			videoLink: card?.repurpose_done_link ? card?.repurpose_done_link : card?.done_video_link,
			videoVersions,
			videoVersionsCount,
		});

		if (isYtSection && videoVersionsCount > 0) {
			setShowVideoLink(videoVersions[`version-${videoVersionsCount - 1}`][0].video_id);
			let temp = videoVersions[`version-0`][0];
			setFetchChatForId(temp.parent_link_id ? temp.parent_link_id : temp.video_id);
		} else if (isYtSection && card?.repurpose_done_videos?.length > 0) {
			setShowVideoLink(card?.repurpose_done_videos[0].video_id);
			setFetchChatForId(card?.repurpose_done_videos[0].parent_link_id);
		} else if (isYtSection) {
			setShowLargeChat(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [card])

	useEffect(() => {
		if (state.videoVersions && state.videoVersionsCount > 0) {
			let options = [];
			let versions = Object.keys(state.videoVersions);

			options = (versions || []).map((v, i) => {
				return <option key={'option-' + v + '-key'} value={v}>{`Version ${i + 1}`}</option>
			});

			setVideoVersionsState(prev => ({ ...prev, currentVersion: versions[state.videoVersionsCount - 1], options }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state?.videoVersions])

	useEffect(() => {
		if (isYtSection && showVideoDetail) {
			setIsGDVideo(false);
			setShowLargeChat(false);
			setCurrentVideoUrl(null);
			let currentId = null;
			let videoArray = null;

			if (state.videoVersions && state.videoVersionsCount > 0) {

				currentId = showVideoLink;
				videoArray = state.videoVersions[videoVersionsState.currentVersion];
			} else if (state.card?.repurpose_status && state.card?.repurpose_done_link && state.card?.repurpose_done_link !== "") {
				let videoUrl = state.card?.repurpose_done_link;

				if (state.card?.done_videos && state.card?.done_video_count > 0) {
					setFetchChatForId(state.card?.done_videos[0].video_id);
				}

				setCurrentVideoLink(videoUrl);
				return;
			} 

			if (videoArray && videoArray.length > 0) {
				let videoData = videoArray.filter(d => d.video_id === currentId);
				let baseVersionVideo = state.videoVersions['version-0'];
				if (videoData[0] && videoData[0].video_url) {
					let videoIndex = videoArray.findIndex(data => data.video_id === currentId);
					if (baseVersionVideo[videoIndex] && baseVersionVideo[videoIndex].parent_link_id) {
						setFetchChatForId(baseVersionVideo[videoIndex].parent_link_id);
					} else if (baseVersionVideo[videoIndex] && baseVersionVideo[videoIndex].video_id) {
						setFetchChatForId(baseVersionVideo[videoIndex].video_id);
					} else {
						let baseVersionVideoId = getBaseVersionVideoId(videoIndex);
						setFetchChatForId(baseVersionVideoId ? baseVersionVideoId : currentId);
					}

					let videoUrl = videoData[0].video_url;

					setCurrentVideoLink(videoUrl);
					return;
				}
			} 

			setCurrentVideoUrl(null);
			// setShowLargeChat(true);
		
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showVideoLink, videoVersionsState]);
	console.log(currentVideoUrl,'curreentVideoUrl');
	useEffect(() => {
		if (currentVideoUrl && currentVideoUrl !== "") {
			let doneVideo = document.getElementById("preview-vid");
			if (doneVideo) {
				doneVideo.load();
			}
		}
	}, [currentVideoUrl]);

	useEffect(() => {
		if (videoVersionsState.currentVersion) {
			setShowVideoLink(state.videoVersions[videoVersionsState.currentVersion][0].video_id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoVersionsState.currentVersion])

	const setCurrentVideoLink = (videoUrl) => {
		let parseVideoUrl = parseVideoLink(videoUrl);
		if (parseVideoUrl) {
			setCurrentVideoUrl(parseVideoUrl);
		} else if (GD_LINK.test(videoUrl)) {
			let matches = videoUrl?.match(GD_LINK);
			if (matches?.length >= 3) {
				setIsGDVideo(true);
				setCurrentVideoUrl(videoUrl);
			}
		} else {
			setShowLargeChat(true);
		}
		return true;
	}

	const getBaseVersionVideoId = (index) => {
		let videoVersions = state.videoVersions;
		for (const version in videoVersions) {
			if (videoVersions.hasOwnProperty(version)) {
				const element = videoVersions[version];
				if (element[index] && element[index].video_id) {
					return element[index].video_id;
				}
			}
		}
		return 0;
	}

	function onVideoPreviewLoadedMetadata() {
		let videoPreview = document.getElementById("preview-vid");
		let duration = videoPreview.duration || 0.0;
		if (duration <= 0.01 || isNaN(duration) || !isFinite(duration)) {
			setShowLargeChat(true);

		}
	}
	function onVideoPreviewLoadedMetadataError() {
		let videoPreview = document.getElementById("preview-vid");
		let duration = videoPreview.duration || 0.0;
		if (duration <= 0.01 || isNaN(duration) || !isFinite(duration)) {
			setShowLargeChat(true);
		}
	}

	const seekVideo = (offset) => {
		const newTime = seeking ? videoRef.current.currentTime + offset : offset;

		// Prevent seeking beyond video bounds
		if (newTime < 0) {
			videoRef.current.currentTime = 0;
		} else if (newTime > videoRef.current.duration) {
			videoRef.current.currentTime = videoRef.current.duration;
		} else {
			videoRef.current.currentTime = newTime;
			setSeeking(true); // Set flag during seek
		}
	};

	// Handle key presses
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (seeking) { // Check if seeking is already in progress
				return; // Prevent further keypress handling during a seek
			}

			switch (event.key) {
				case 'ArrowRight':
					seekVideo(5);
					break;
				case 'ArrowLeft':
					seekVideo(-5);
					break;
				default:
					break;
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		// Remove event listener on unmount
		return () => document.removeEventListener('keydown', handleKeyDown);
	}, [seeking]);

	// Additional effect to reset flag after seek completes
	useEffect(() => {
		if (seeking && !videoRef.current.seeking) { // Check if video is no longer seeking
			setSeeking(false);
		}
	}, [seeking, videoRef]);

	const handleChangeDisplayVideo = (data) => {
		setShowVideoLink(data);
	}

	const handleMapVideoOption = () => {
		let tempFields = [];
		let videos = state.videoVersions[videoVersionsState.currentVersion];
		videos && videos.length > 1 && videos.forEach((data, i) => {
			tempFields.push(
				<div onClick={() => handleChangeDisplayVideo(data.video_id)} className={`tab-vid-inn ${showVideoLink === data.video_id ? 'active' : ''}`} key={"video-radio-" + data.video_id}>
					Video {i + 1}
				</div>
			);
		});
		return tempFields;
	}

	return (
		<Row gutter={[16, 24]} className={styles.wrapper}>
			<Col span={14} className={styles.leftSection}>
				{
					viewType  && state.videoVersions[videoVersionsState.currentVersion] && (state.videoVersions[videoVersionsState.currentVersion]).length > 1 &&
					<Row className={`tab-vid ${styles.videoTabs}`}>
						<Col s={12} m={12} className="radio-cover">
							{
								handleMapVideoOption()
							}
						</Col>
					</Row>
				}
				{
					viewType  && !showLargeChat ?
						<>
							<div className={styles.previewWrapper}>
								<Typography.Title level={4} className={styles.cardTitle}>
									Preview
								</Typography.Title>
								{
									state.videoVersions && state.videoVersionsCount > 1 ?
										<MaterialSelect
											value={videoVersionsState.currentVersion}
											onChange={e => { let temp = e.target.value; setVideoVersionsState(prev => ({ ...prev, currentVersion: temp })); }}
										>
											{videoVersionsState.options}
										</MaterialSelect> : null
								}
							</div>
							<div className={styles.videoPreview}>
								{currentVideoUrl && !isGDVideo ? (
									<video id="preview-vid" preload="metadata" controls ref={videoRef}
										style={{ width: "100%", maxHeight: "65vh", height: isMobile ? "inherit" : "55vh", border: "1px solid black", borderRadius: "5px" }}
										onLoadedMetadata={e => onVideoPreviewLoadedMetadata()}
										onError={(e) => onVideoPreviewLoadedMetadataError()}>
										<source src={currentVideoUrl} />Video Preview
									</video>
								) : isGDVideo ? (
									<div className={styles.unSupportedVideo}>
											Unsupported video
									</div>
								) : (
									<div className={styles.unSupportedVideo}>
											No video added yet.
									</div>
								)}
							</div>
						</> : null
				}
			</Col>

			{viewType  && showVideoDetail && !showLargeChat &&
				<Col span={10} className={styles.rightSection}>
					<div className={styles.chatBoxWrapper}>
						<div>
							<Row className="chatbox">
								<ChatBox
									cardId={cardId}
									me={loggedInUser}
									poll={true}
									card={state.card}
									videoCount={state.card?.repurpose_video_count > 0 ? state.card?.repurpose_video_count : state.card?.done_video_count}
									showVideoChat={fetchChatForId}
									allowNewChat={allowNewChat}
								/>
							</Row>
						</div>
					</div>
				</Col>
			}
		</Row>
	);
};

export default CardVideo;
