import React from 'react';
import { Space, Typography, Card, Avatar, Tooltip, Tag, Dropdown } from 'antd';
import { Col, Row} from "react-materialize";
import { TagOutlined, CommentOutlined } from '@ant-design/icons';
import OptionIcon from '../../../img/options_icon.png';
import { addCardStatus } from '../Utilities/utils';
import { Link } from 'react-router-dom';
import { AiOutlineTag } from  'react-icons/ai';
import { useSelector } from 'react-redux';
import { CANCELED, ON_HOLD, YT_HELPER_IN_PROGRESS, YT_HELPER_SECTION } from '../../../../api/Constants';
import { getCardActivityStatus } from '../../../../services/cardActivityStatus';
import styles from './ListItem.module.scss';
import { errorToast, getUserShortName, isAdmin, isCustomer, isMobile } from '../../../../api/Util';
import './ListItemStyle.scss';
import { AiOutlineCheck } from 'react-icons/ai';
import { VscComment } from 'react-icons/vsc';

function ListItem({ card, provided, mapIcons, activeTab, section, getDueDateRow, getYTCardDueDateRow, setCardActivityModal, setCardActivityData, getData, handleDropdownItemClick, objKey, setCardRepurposeModal, setCurrentCardId, currentCardRepurpose }) {
    const auth = useSelector((state) => state.auth);
    const admin = isAdmin(auth.loggedInUser);
    const customer = isCustomer(auth.loggedInUser);
    const viewType = localStorage.getItem("viewType");

    const getStatus = () => {
        if (viewType === "normal") {
            if (card.repurpose_status === "editing") {
                return "repurpose_editing";
            } else if (card.repurpose_status === "done") {
                return "repurpose_done";
            } else {
                return card.card_status;
            }
        } else {
                if (card.ytHelper_card_status === null) {
                    return "assigned";
                } else {
                    return card.ytHelper_card_status;
                }
            }
    }

    return (
        <Link to={{
                pathname: `/card-details/${card.card_id}`,
                state: { cardId: card.card_id }
            }}{...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            <Card
                className={`${styles.cards} collapsibleCards`}
                loading={false}
                onClick={() => {
                    const cmtId = "comment-outlined-" + card.card_id;
                    if ((card.unseenComments === 0) && (card?.qa_assigned_to ===  auth?.loggedInUser?.client_id)) {
                        document.getElementById(cmtId).style.display = "none";
                    }
                    if ((card.unseenComments_ytHelper === 0) && (card?.yt_helper_id ===  auth?.loggedInUser?.client_id)) {
                        document.getElementById(cmtId).style.display = "none";
                    }
                }}
            >
            <div className={styles.cardMetaContainer}>
                <Card.Meta
                    className={styles.cardListDescription}
                    title= {
                        <div className={styles.cardListTitle}>
                            {
                                activeTab === "all_tabs" ? 
                                <div className={styles.roundDotWrapper}>
                                    <div className={`roundDot-${card.card_status} desktop-mode`}></div>
                                    <p>{card.card_title}</p>
                                </div> :
                                <div className={styles.roundDotWrapper}>
                                    <div className={`roundDot-${activeTab} desktop-mode`}></div>
                                    <p>{card.card_title}</p>
                                </div>
                            }
                            <Tag className={activeTab === "all_tabs" ? `${card.card_status} desk-mode` : `${activeTab} desk-mode`} color="success">{addCardStatus(activeTab === "all_tabs" ? card.card_status : activeTab)}</Tag>
                        </div>
                    }
                    description={
                        <Space direction="vertical" className={styles.spaceContainer}>
                            <Space className={styles.mobileMode}>
                                {auth.loggedInUser.client_type !== 'customer' &&
                                    <div className={`${styles.iconAndTextOuterContainer} ${styles.mobileMode}`}>
                                        <div className={styles.iconAndTextContainer}>
                                            <TagOutlined className={styles.icon} />
                                            <Typography.Text className={styles.greyFontColor}>{card.subscription_type}</Typography.Text>
                                        </div>
                                    </div>
                                }
                                { viewType !== "yt-helper" ? getDueDateRow(card, admin, "mobile-mode") : getYTCardDueDateRow(card, customer, "mobile-mode")}
                            </Space>
                            {card.yt_helper_id || card.comments > 0 ?
                                <Space className={styles.mobileMode}>
                                    {
                                        viewType === "normal" && section !== YT_HELPER_SECTION && card.comments > 0 &&
                                            <div className={`${styles.iconAndTextContainer} ${styles.mobileMode}`}>
                                                <CommentOutlined className={styles.icon} />
                                                <Typography.Text className={styles.greyFontColor}>{card.comments}</Typography.Text>
                                            </div>
                                    }
                                    {
                                        card.yt_helper_id && 
                                            <div className={`${styles.iconAndTextContainer} ${styles.mobileMode}`}>
                                                <AiOutlineTag className={styles.icon} /><strong className={styles.greyFontColor}>{"YT Helper Status: " + (card.ytHelper_card_status ? 
                                                    (card.ytHelper_card_status ===YT_HELPER_IN_PROGRESS ? "Progress" : "Done") :"ASSIGNED CARD")}</strong>
                                            </div>
                                    }
                                </Space> : null
                            }
                            <Space className={`${styles.avatarIconGroup} spaceContainerIcon_wrapper`}>
                                <Tooltip title={
                                                card.firstname || card.lastname ? `${card.firstname || ''} ${card.lastname || ''}` : 'N/A'
                                            } placement="top">
                                    <Avatar className={styles.avatarStyles} style={{ backgroundColor: '#f56a00' }}>
                                        {card.firstname || card.lastname ?
                                            `${card.firstname.toUpperCase().charAt(0)}${card.lastname.toUpperCase().charAt(0)}` : 'N/A'}
                                    </Avatar>
                                </Tooltip>
                                <div>
                                    <Tooltip title={
                                                card.qa_firstname || card.qa_lastname ? getUserShortName(`${card.qa_firstname || ''} ${card.qa_lastname || ''}`) : 'N/A'
                                            } placement="top">
                                        <Avatar className={styles.avatarStyles} style={{ backgroundColor: '#87d068' }}>
                                            {card.qa_firstname || card.qa_lastname ? 
                                                `${card.qa_firstname.toUpperCase().charAt(0)}${card.qa_lastname.toUpperCase().charAt(0)}` : 'N/A'}
                                        </Avatar>
                                    </Tooltip>
                                </div>
                                {auth.loggedInUser.client_type !== 'customer' &&
                                    <div className={`${styles.iconAndTextOuterContainer} ${styles.deskMode}`}>
                                        <div className={styles.iconAndTextContainer}>
                                            <TagOutlined />
                                            <Typography.Text className={styles.greyFontColor}>{card.subscription_type}</Typography.Text>
                                        </div>
                                    </div>
                                }
                                {
                                    viewType === "yt-helper" ? card.yt_comments > 0 ?
                                        <div className={`${styles.iconAndTextContainer} ${styles.deskMode}`}>
                                            <CommentOutlined />
                                            <Typography.Text className={styles.greyFontColor}>{card.yt_comments}</Typography.Text>
                                        </div> : null
                                        : card.comments > 0 ?
                                        <div className={`${styles.iconAndTextContainer} ${styles.deskMode}`}>
                                            <CommentOutlined />
                                            <Typography.Text className={styles.greyFontColor}>{card.comments}</Typography.Text>
                                        </div> : null
                                }
                                {
                                    (((
                                        mapIcons(card)?.ratingStar || mapIcons(card)?.completeIcon || mapIcons(card)?.repurposeRatingStar ||
                                        mapIcons(card)?.repurposeCompleteIcon || mapIcons(card)?.revisionRepurposeIcon ||
                                        mapIcons(card)?.revisionIcon || mapIcons(card)?.pausedIcon || mapIcons(card)?.repurposeIcon || card.unseenComments === 0 || card.unseenComments_ytHelper === 0
                                    ) && viewType === "normal") || (mapIcons(card)?.ytCompleteIcon && viewType === "yt-helper")) &&
                                        <div className={styles.mapIcons}>
                                            {viewType === "normal" && mapIcons(card)?.ratingStar}
                                            {viewType === "normal" && mapIcons(card)?.completeIcon}
                                            {viewType === "normal" && mapIcons(card)?.repurposeRatingStar}
                                            {viewType === "normal" && mapIcons(card)?.repurposeCompleteIcon}
                                            {viewType === "normal" && mapIcons(card)?.revisionRepurposeIcon}
                                            {viewType === "normal" && mapIcons(card)?.revisionIcon}
                                            {viewType === "normal" && mapIcons(card)?.pausedIcon}
                                            {viewType === "normal" && mapIcons(card)?.repurposeIcon}
                                            {viewType === "yt-helper" && mapIcons(card)?.ytCompleteIcon}
                                            {mapIcons(card)?.ytHelperRatingStar}
                                            {card?.unseenComments === 0 && <Tooltip title="New Comment Added" placement="top">
                                                    <CommentOutlined id={`comment-outlined-${card.card_id}`} style={{ color: "green"}} />
                                                </Tooltip>
                                            }
                                            {card?.unseenComments_ytHelper === 0 && <Tooltip title="New Comment Added" placement="top">
                                                    <CommentOutlined id={`comment-outlined-${card.card_id}`} style={{ color: "red"}} />
                                                </Tooltip>
                                            }
                                        </div>
                                }
                                { viewType !== "yt-helper" ? getDueDateRow(card, admin, "desk-mode") : getYTCardDueDateRow(card, customer, "desk-mode")}
                                {
                                    card.yt_helper_id && 
                                        <div className={`${styles.iconAndTextContainer} ${styles.deskMode}`}>
                                            <AiOutlineTag/>&nbsp;<strong>{"YT Helper Status: " + (card.ytHelper_card_status ? 
                                                (card.ytHelper_card_status ===YT_HELPER_IN_PROGRESS ? "Progress" : "Done") :"ASSIGNED CARD")}</strong>
                                        </div>
                                }

                            <Row className={styles.cardActivityRow}>
                                {!customer ?
                                            <button className={styles.activityStatusBtn} onClick={async (event) => {
                                                setCardActivityData([]);
                                                event.preventDefault();
                                                setCardActivityModal(true);
                                                const data = await getCardActivityStatus(card.card_id);
                                                if (data.status) {
                                                    setCardActivityData(data.data);
                                                } else {
                                                    setCardActivityData([]);
                                                    errorToast(data.error);
                                                }
                                            }}>
                                                <strong>Activity</strong>
                                            </button>: null
                                }
                                  { !card.repurpose_aspect && currentCardRepurpose ?
                                    <button className={styles.activityStatusBtn} onClick={async (event) => {
                                        event.preventDefault();
                                        setCardRepurposeModal(true);
                                        setCurrentCardId(card.card_id)
                                    }}>
                                    <strong>  {!isMobile && <AiOutlineCheck />} Repurpose</strong>
                                </button>: null
                      }
                                    </Row> 
                                    
                            </Space>
                            <Space className={`${styles.avatarIconGroup} ratingCommentTextOuterContainer`}>
                            {
                                card?.doneCardRatingComment && 
                                    <div className={`${styles.iconAndTextContainer} ${styles.deskMode} ${styles.ratingCommentTextContainer}`}>
                                        <VscComment/>&nbsp;
                                        {card.doneCardRatingComment}
                                    </div>
                            }
                            {
                                card?.repurposeDoneCardRatingComment && 
                                    <div className={`${styles.iconAndTextContainer} ${styles.deskMode} ${styles.ratingCommentTextContainer}`}>
                                        <VscComment/>&nbsp;
                                        {card.repurposeDoneCardRatingComment}
                                    </div>
                            }
                            </Space>
                        </Space>
                        }
                    />
                    <a className={styles.optionIconWrapper} onClick={e => e.preventDefault()}>
                        <Dropdown
                            menu={{
                                items: getData(getStatus()),
                                onClick: (e) => (handleDropdownItemClick(e, card, objKey))
                            }}
                            trigger={['click']}
                        >
                            <img src={OptionIcon} alt="OptionIcon" />
                        </Dropdown>
                    </a>
                </div>
            </Card>
        </Link>
    )
}

export default ListItem;
