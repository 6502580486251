import React, { useEffect, useState } from 'react';
import { Typography, Space, Button, Divider, Row, Col, Avatar, Tooltip, Modal, Spin, Select, DatePicker } from 'antd';
import { TextInput, Button as MaterialButton, Switch } from "react-materialize";
import ConfirmationDialog from "../../ConfirmationDialog";
import styles from './YTHelperTabStyle.module.scss';
import TimelineIcon from '../../../components/img/timeline_icon.png';
import { CloseOutlined, DownloadOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import AssignedToIcon from '../../../components/img/assigned_to_icon.png';
import DueOnIcon from '../../../components/img/due_on_icon.png';
import RawVideoLinkIcon from '../../../components/img/raw_video_link_icon.png';
import VideoInfoIcon from '../../../components/img/video_info_icon.png';
import MobileFormatIcon from '../../../components/img/mobile_link.png';
import MobileEyeIcon from '../../../components/img/mobile-eye-icon.png';
import MobileCalenderIcon from '../../../components/img/mobile-calender.png';
import { ADMIN, CUSTOMER, DONE, TEAM_LEAD, VIDEO_REQUEST, WEEKLY, YT_HELPER, YT_HELPER_ASSINED, YT_HELPER_CANCELLED, YT_HELPER_COMPLETED, YT_HELPER_IN_DONE, YT_HELPER_IN_PROGRESS, YT_HELPER_LEAD, YT_HELPER_SECTION } from '../../../api/Constants';
import { cardInProgress, dateFormatISO, errorToast, infoToast, isAdmin, isCustomer, parseSqlDateTime, successToast, warningToast, getCardDisplayStatus, isMobile, primaryColor, disableColor, getUserShortName } from '../../../api/Util';
import { axiosClient } from '../../../api/httpClient';
import MobileModal from '../../common/MobileModal/MobileModal';
import { getCardDetail, giveRatingToCard } from '../../../services/cardsService';
import { moveYtCard, moveYtHelperCardInProgress, markYtCardAsCompleted } from '../../../api/CardActions';
import { SELECTED_CARD_SECTION } from '../../../actions/types';
import Annotation from 'react-image-annotation';
import {
    PointSelector,
    RectangleSelector,
    OvalSelector
} from 'react-image-annotation/lib/selectors'
import ChatBox from '../CardDetailsTabs/VideoCommentsTab/ChatComponent/ChatBox';
import YTHelperCardMovementModal from '../../common/YTHelperCardMovementModal/YTHelperCardMovementModal';
import YTHelperCommonModal from '../../common/YTHelperCommonModal/YTHelperCommonModal';
import "./YTHelperTabStyle.scss";
import Ratings from "react-ratings-declarative";

const YTHelperTab = ({ card, setCardDetail, currentVideoUrl, section, viewType }) => {
    const INITIAL_STATE = {
        card: {
            card_title: "Card Details"
        },
        videoLink: '',
        videoVersions:{},
        videoVersionsCount:0,
    };

    const MODAL_MOBILE_DATA = {
        isModalOpen: false,
        title: "",
        modalContent: "",
        type: "",
    };

    const INITIAL_ANNOTATION_STATE = {
        type: RectangleSelector.TYPE,
        annotation : {},
        annotations : [],
        activeAnnotations : [],
        showAnnotations:true,
        callChatApi:false
    }
    const GD_LINK = /http(s)?:\/\/drive\.google\.com\/file\/d\/([^\\]+)\/.*/i;
   
    const [isRevisionModalOpen, setIsRevisionModalOpen] = useState(false);
    const [isMarkDoneModalOpen, setIsMarkDoneModalOpen] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [changeDoneVideoUrlState, setChangeDoneVideoUrlState] = useState({isEditing: false});
    const [changeRepurposeVideoUrlState, setChangeRepurposeVideoUrlState] = useState({isEditing: false});
    const [videoVersionsState, setVideoVersionsState] = useState({currentVersion:null,options:[]});
    const [state, setState] = useState(INITIAL_STATE);
    const [options, setOptions] = useState([]);
    const [assignQa, setAssignQa] = useState({ value: `${state.card?.assigned_qa_id}`, label: `${getUserShortName(state.card?.assigned_to)}` } || "0");
    const [updatedDueDate, setUpdatedDueDate] = useState();
    const [width, setWidth] = useState(window.innerWidth);
    const [mobileModalData, setMobileModalData] = useState(MODAL_MOBILE_DATA);
    const [type, setType] = useState("");
    const [changeUnlistedVideoUrlState, setChangeUnlistedVideoUrlState] = useState({isEditing: false});
    const [changeVideoThumbnailLink, setChangeVideoThumbnailLink] = useState({isEditing: false});
    const [changeSnippetVideoLink, setChangeSnippetVideoLink] = useState({isEditing: false});
    const [ytConfirmationOpen, setYTConfirmationOpen] = useState(false);
    const [assignYT, setAssignYT] = useState({value : `${state.card?.yt_helper_id}`,label:`${getUserShortName(state.card?.yt_assigned_to)}`});
    
    // for yt helper dashboard card movement 
    const [moveCardInProgress, setMoveCardInProgress] = useState({open:false});
    const [ytHelperAssetsModal, setYtHelperAssetsModal] = useState({open:false});
    const [confirmationYtCard, setConfirmationYtCard] = useState({open:false});
    const [moveCardInCompleted, setMoveCardInCompleted] = useState({open:false});
    const [thumbnailsLink, setThumbnailsLink] = useState("");
    const [snippetLink, setSnippetLink] = useState("");
    const [unlistedLink, setUnlistedLink] = useState("");
    const [videoDescription, setVideoDescriptions] = useState("");
    const [editorState, setEditorState] = useState();
    const [thumbnailLinkUrlArray, setThumbnailLinkUrlArray] = useState([]);
    const [showThumbnailLink, setShowThumbnailLink] = useState();
    const [currentThumbnailUrl, setCurrentThumbnailUrl] = useState();
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");
    const [isRatingsModalOpen, setIsRatingsModalOpen] = useState(false);
    
    // For Annotation :
    const [annotationStates, setAnnotationStates] = useState(INITIAL_ANNOTATION_STATE);
    const [showLargeChat, setShowLargeChat] = useState(false);

    const props = useSelector((state) => state)
    const loggedInUser = props.auth?.loggedInUser;
    const websiteUsers = useSelector(state => state.allWebsiteUsers.website_users);
    // const viewType = localStorage.getItem("viewType");
    const dispatch = useDispatch();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const YTHelperStatus = [
        { value: "assigned", label: "Assigned" },
        { value: "inProgress", label: "In Progress" },
        { value: "inDone", label: "Review" },
        { value: "cancelled", label: "Cancelled" },
        { value: "completed", label: "Completed" },
    ];

    useEffect(() => {
        if (!card?.thumbnails_link) {
            setShowLargeChat(true);
        } else if (card?.thumbnails_link) {
            if (GD_LINK.test(card.thumbnails_link)) {
                let matches = card.thumbnails_link
                if (matches?.length >= 3) {
                    setShowLargeChat(false);
                } else {
                    setShowLargeChat(true);
                }
            } else {
                setShowLargeChat(false);
            }
        }
        let videoVersionsCount = 0;
        let videoVersions = [];

        if ( card?.repurpose_video_versions && Object.keys(card?.repurpose_video_versions).length > 0) {
            videoVersions = card?.repurpose_video_versions;
            videoVersionsCount = Object.keys(card?.repurpose_video_versions).length;
        } else if ( card?.video_versions && Object.keys(card?.video_versions).length > 0) {
            videoVersions = card?.video_versions;
            videoVersionsCount = Object.keys(card?.video_versions).length;
        }
        setState({
            card: card,
            dueDate: viewType === "yt-helper" ? ( card?.yt_due_date ? parseSqlDateTime(card?.yt_due_date) : null ) : ( card?.due_date ? parseSqlDateTime(card?.due_date) : null ),
            videoLink:card?.repurpose_done_link ? card.repurpose_done_link : card?.done_video_link,
            videoVersions,
            videoVersionsCount,
        });
        setAssignQa({ value: `${card?.assigned_qa_id}`, label: `${getUserShortName(card?.assigned_to)}` });
        setAssignYT({ value: card?.yt_helper_id, label: getUserShortName(card?.yt_assigned_to)} || "0");

        if (card?.thumbnail_links.length > 0) {
            setShowThumbnailLink(card?.thumbnail_links[0]?.video_id);
            setCurrentThumbnailUrl(card?.thumbnail_links[0]?.video_url);
        } else {
            setCurrentThumbnailUrl(card?.thumbnails_link);
            setShowThumbnailLink(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [card])

    useEffect(() => {
        var option = (websiteUsers || [])?.filter(user => user.client_type === YT_HELPER || user.client_type === YT_HELPER_LEAD).map(user => {
                return {
                    value:`${user.client_id}` , 
                    label:`${getUserShortName(user.fullname)} (${user.client_type})`
                }
            })
            option = [{value:"0" ,label:"None"},...option];
            setOptions(option);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [section, websiteUsers])

    useEffect(() => {
        if (state.videoVersions && state.videoVersionsCount > 0) {
            let options = [];
            let versions = Object.keys(state.videoVersions);

            options = (versions || []).map((v, i) => {
                return <option key={'option-' + v + '-key'} value={v}>{`versions ${i + 1}`}</option>
            });
            setVideoVersionsState(prev => ({ ...prev, currentVersion: versions[state.videoVersionsCount - 1], options }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.videoVersions])

    const handleOkRevision = () => {
        setIsRevisionModalOpen(false);
        let ytHelperUrl = `/api/ythelper/card/${card?.card_id}/revision`;

        axiosClient.post(ytHelperUrl, {
            card_id: card?.card_id, card_status: card?.card_status
        }).then(({data}) => {
                updateCardDetail();
                successToast(data.success || "Card moved to revision successfully");
            }).catch(err => {
                let message = "Something went wrong, please try again";
                if(err?.response?.data && err.response.data.message && err.response.data.message !== "") {
                    message = err.response.data.message;
                }
                errorToast(message);
                console.error(err);
            });
    };

    const handleCancelRevision = () => {
        setIsRevisionModalOpen(false);
    };

    const handleOkMarkDone = () => {
        setIsMarkDoneModalOpen(false);
        let ytCardUrl = `/api/ythelper/card/${card?.card_id}/markComplete`;
        let cardSection = viewType !== "yt-helper" && card?.card_status === DONE && card?.repurpose_status === DONE ? "repurpose" : "done";

        axiosClient.post(ytCardUrl, {card_id: card?.card_id, status: 1,cardSection, buttonText: "Mark As Done" })
            .then(({data}) => {
                updateCardDetail();
                successToast(data.success || "Card marked completed!");
                handleOkRatings();
                cardSection = { cardType: "normal", section: "done", markAsDone: 1 };
                dispatch({
                    type: SELECTED_CARD_SECTION,
                    payload: { status: 200, data: cardSection }
                })
            }).catch(err => {
                let message = "Something went wrong, please try again";
                if(err?.response?.data && err.response.data.message && err.response.data.message !== "") {
                    message = err.response.data.message;
                }
                errorToast(message);
                console.error(err);
            });
    };

    const handleCancelMarkDone = () => {
        setIsMarkDoneModalOpen(false);
    };

    function changeYTHelper() {
        return <div>
            <ConfirmationDialog
                key="confirm-assign-yt"
                onNegative={() => setYTConfirmationOpen(false)}
                onPositive={onConfirmChangeYTHelper}
                confirmationHeader="Confirmation"
                confirmationDialogId="confirm-assign-yt-dialog"
                confirmation={ytConfirmationOpen}
                confirmationText="Are you sure you want to change assigned YT Helper?"
            />
            <Select
                className={`${styles.qaSelectBox} selectBox`}
                classNamePrefix="select"
                    value={assignYT === 'null' || null || 0 ? { value:'0', label:'None'} : assignYT}
                    options = {options}
                    onChange={e => { setAssignYT(e); setYTConfirmationOpen(true); }}>
            </Select>
        </div>;
    }
    
    function onConfirmChangeYTHelper() {
        infoToast("Changing assigned YT Helper");
        axiosClient.post('/api/ythelper/card/assign', { card_id: card.card_id, assign_to: Number(assignYT)})
            .then(({data}) => {
                if(data.success) {
                    updateCardDetail();
                    successToast(data.message);
                } else {
                    errorToast(data.message);
                }
            });
    }

    function makeQaAndDueDateRow() {
        if(isAdmin(props.auth.loggedInUser) || props.auth.loggedInUser.client_type === YT_HELPER_LEAD) {
            return React.Children.toArray([
                <Row className ="bottom_row">
                    <div className="inside_linkg">
                        {changeYTHelper()}
                    </div>
                </Row>,
            ]);
        }
    }

    async function updateDueDate(e) {
        setState(prev => ({...prev, dueDate: dateFormatISO(e.$d)}));
        if(!dateFormatISO(e.$d)) {
            warningToast("Please select a valid due date");
            return;
        }

        let newDueDate = dateFormatISO(e.$d);
        // for yt helper card due date
        const ytCardApi = '/api/ythelper/card/updateDueDate';

        await axiosClient.post(ytCardApi, {card_id: state.card.card_id, due_date: newDueDate})
            .then(async () => {
                updateCardDetail();
                setUpdatedDueDate(newDueDate);
                successToast(`Card ${state.card.card_title} due date changed to ${newDueDate}`);
            }).catch(err => {
                errorToast('Something went wrong, please try again');
                console.error(err);
            })
    }

    const updateCardDetail = async () => {
        const data = await getCardDetail(card.card_id);
        if (data.status) {
            setCardDetail(data.data);
        } else {
            errorToast(data.error);
        }
    }

    const handleDoneVideoChange = ()=>{
        if (changeUnlistedVideoUrlState.isEditing && !changeUnlistedVideoUrlState.newVideoLink) {
            return;
        }
        if (changeVideoThumbnailLink.isEditing && !changeVideoThumbnailLink.newVideoLink) {
            return;
        }
        if (changeSnippetVideoLink.isEditing && !changeSnippetVideoLink.newVideoLink) {
            return;
        }
        let obj;
        if (changeUnlistedVideoUrlState.isEditing) {
            obj = {
                card_id: card.card_id,
                unlisted_video_link: changeUnlistedVideoUrlState.newVideoLink,
                type: YT_HELPER_IN_DONE,
            };
        } else if (changeVideoThumbnailLink.isEditing) {
            obj = {
                card_id: card.card_id,
                thumbnails_link: changeVideoThumbnailLink.newVideoLink,
                type: YT_HELPER_IN_DONE,
                video_id: changeVideoThumbnailLink.oldVideoId,
            };
        } else if (changeSnippetVideoLink.isEditing) {
            obj = {
                card_id: card.card_id,
                yt_snippet_link: changeSnippetVideoLink.newVideoLink,
                type: YT_HELPER_IN_DONE,
            };
        }
        
        obj = {...obj,updateBothTable:false};

        axiosClient.post("/api/card/changeDoneVideo",obj)
            .then(({data})=>{
                successToast(data?.message);
                updateCardDetail();
                setChangeUnlistedVideoUrlState( { isEditing: false });
                setChangeSnippetVideoLink( { isEditing: false });
                setChangeVideoThumbnailLink( { isEditing: false });
            })
            .catch((err)=>{
                errorToast(err?.response?.data?.message || "Something went wrong, please try again");
            })
    }

    const clearFields = () => {
        setMobileModalData(MODAL_MOBILE_DATA)
    }

    const handleCancelMobileModal = () => {
        setMobileModalData(MODAL_MOBILE_DATA)
    }

    const markAsDoneButton = () => {
        if (
            (!isCustomer(loggedInUser) && !isAdmin(loggedInUser))
            || (card?.yt_card_status !== YT_HELPER_IN_DONE)
            || (card?.yt_is_complete)
            ) {
                return "";
        }
        if (!card?.yt_is_complete) {
            return <Button type="primary" size={'large'} className={styles.readyForUploadBtn} onClick={() => setIsMarkDoneModalOpen(true)}>
                    Mark As Done
                </Button>
        }
        return "";
    }

    const makeDueDateRow = () => {
        if (isAdmin(loggedInUser) || loggedInUser.client_type === YT_HELPER_LEAD) {
            if (state.card.ytHelper_card_status === YT_HELPER_IN_PROGRESS) {
                return <>
                    <Col span={isMobile ? 9 : 8}>
                        <div className={styles.iconsAndText}>
                            <img src={DueOnIcon} className={styles.iconsStyle} alt="DueOnIcon" />
                            <Typography.Text className={styles.headingFontStyle}>
                                Due on:
                            </Typography.Text>
                        </div>
                    </Col>
                    {
                        !isMobile &&
                            <Col span={16}>
                                <Typography.Text className={styles.greyFontColor}>
                                    <div className={styles.deskMode}>{updatedDueDate || viewType === "yt-helper" ? card?.convertedYTHelperTime : card?.convertedTime}</div>
                                </Typography.Text>
                            </Col>
                    }
                    {
                    !isMobile &&
                        <Col span={8}>
                            
                        </Col>
                    }
                    <Col span={isMobile ? 13 : 16}>
                        <DatePicker className={styles.setDueDateStyle} placeholder="Select Due Date" showTime onOk={(e) => updateDueDate(e)} />
                    </Col>
                </>
            }

        } else {
            if (state.card?.yt_due_date && state.card?.ytHelper_card_status !== YT_HELPER_IN_DONE) {
                return <>
                    <Col span={isMobile ? 9 : 8}>
                        <div className={styles.iconsAndText}>
                            <img src={DueOnIcon} className={styles.iconsStyle} alt="DueOnIcon" />
                            <Typography.Text className={styles.headingFontStyle}>
                                Due on:
                            </Typography.Text>
                        </div>
                    </Col>
                    {
                        !isMobile &&
                            <Col span={16}>
                                <Typography.Text className={styles.greyFontColor}>
                                    <div className={styles.deskMode}>{updatedDueDate || card?.convertedYTHelperTime}</div>
                                </Typography.Text>
                            </Col>
                    }
                </>
            }

        }
    }

    const handleDropdownItemClick = (status, card) => {
        let targetStatus = status;
        let sourceStatus = card?.yt_card_status === null ? "assigned" : card?.yt_card_status;
        let cardId = card.card_id;

        if (sourceStatus || targetStatus) {
            if (targetStatus === "assigned") {
                targetStatus = "assignedCard";
            }

            if (sourceStatus === "assigned") {
                sourceStatus = "assignedCard";
            }
            // setting the new status and open Confirmation Dialog 
            let state = {open: true, cardId, newStatus: targetStatus, cardStatus: sourceStatus};
            if (targetStatus === YT_HELPER_IN_PROGRESS && sourceStatus !== YT_HELPER_IN_DONE) {
                setMoveCardInProgress(state);
            }
            else if (targetStatus === YT_HELPER_IN_DONE && sourceStatus !== YT_HELPER_ASSINED) {
                setYtHelperAssetsModal(state);
            }
            else if (targetStatus === YT_HELPER_CANCELLED && loggedInUser.client_type !== YT_HELPER) {
                setConfirmationYtCard(state);
            } else if (targetStatus === YT_HELPER_ASSINED && sourceStatus === YT_HELPER_CANCELLED && loggedInUser.client_type !== YT_HELPER) {
                setConfirmationYtCard(state);
            } else if (targetStatus === YT_HELPER_COMPLETED && sourceStatus === YT_HELPER_IN_DONE && loggedInUser.client_type !== YT_HELPER) {
                setMoveCardInCompleted(state);
            }
            return;
        }
    };

    const onChangeAnnotation = (annotation) => {
        setAnnotationStates(prev=>({
            ...prev,
            annotation
        }));
    }

    const onSubmitAnnotation = (annotation) => {
        const { geometry, data } = annotation

        let annotationObj = JSON.stringify({
            geometry,
            data
        });

        const obj = {
            card_id: card?.card_id, 
            note: annotationObj,
            note_type:"annotation",
            note_for_video_number: showThumbnailLink  ? showThumbnailLink  : '1',
        }

        axiosClient.post(`/api/ythelper/card/${card?.card_id}/comment`,obj)
            .then(({data})=>{
                setAnnotationStates(prev=>({
                    ...prev,
                    callChatApi:true
                }));
                successToast("Annotation is added successfully");
            })
            .catch((err)=>{
                console.error(err);
                errorToast("Unable to post annotation, try again later");
            })
    }

    const onAnnotationMouseOver = (id) => e => {
        setAnnotationStates(prev => ({
            ...prev,
            activeAnnotations: [
                ...annotationStates.activeAnnotations,
                id
            ]
        }))
    }

    const onAnotationMouseOut = (id) => e => {
        const index = annotationStates.activeAnnotations.indexOf(id)

        setAnnotationStates(prev => ({
            ...prev,
            activeAnnotations: [
                ...annotationStates.activeAnnotations.slice(0, index),
                ...annotationStates.activeAnnotations.slice(index + 1)
            ]
        }))
    }

    const activeAnnotationComparator = (a, b) => {
        return a.id === b
    }

    const onChangeType = (e) => {
        setAnnotationStates(prev=>({
            ...prev,
            type:e.currentTarget.innerHTML
        }));
    }

    function commonThumbnailImg(src) {
        return (
            <Row gutter={[16, 24]} className={styles.wrapper}>
                <Col span={14} className={styles.leftSection}>
                    <h5 className="video_heading">Thumbnail Preview</h5>
                    <div className="outer_video_row">
                    {/* <Col className="video_col" s={12} m={12}> */}
                        <div>
                        <MaterialButton onClick={onChangeType}>
                            {RectangleSelector.TYPE}
                        </MaterialButton>
                        <MaterialButton onClick={onChangeType}>
                            {PointSelector.TYPE}
                        </MaterialButton>
                        <MaterialButton onClick={onChangeType}>
                            {OvalSelector.TYPE}
                        </MaterialButton>
                        <div className={`switch-annotation ${styles.annotationWrapper}`} style={{ float: 'right' }} >
                            <Switch
                                offLabel ="Annotations"
                                checked={annotationStates.showAnnotations}
                                onChange = {()=>
                                    setAnnotationStates(prev=>({
                                        ...prev,
                                        showAnnotations:!annotationStates.showAnnotations
                                    }))
                                }
                            />
                        </div>
                        <Annotation
                            style={{ width: "100%", maxHeight: "65vh", border: "1px solid black", borderRadius: "5px" }}
                            src = {src}
                            // src={"https://vidchops-videos.s3-accelerate.amazonaws.com/773/Thumbnail_Meme-Stocks.jpg"}
                            alt = "Thumbnail"

                            activeAnnotationComparator={activeAnnotationComparator}
                            activeAnnotations={annotationStates.showAnnotations ? annotationStates.activeAnnotations :[]}

                            annotations={annotationStates.showAnnotations ? annotationStates.annotations:[]}
                            type={annotationStates.type}
                            value={annotationStates.showAnnotations ? annotationStates.annotation :[]}
                            onChange={onChangeAnnotation}
                            onSubmit={onSubmitAnnotation}
                            disableAnnotation ={!annotationStates.showAnnotations}
                        />
                        </div>
                    </div>

                </Col>
                <Col span={10} className={styles.rightSection}>
                    <div className={styles.chatBoxWrapper}>
                        <div>
                            <Row className="chatbox">
                                <ChatBox cardId={card.card_id} me={loggedInUser} poll={true} section={YT_HELPER_SECTION}
                                    card={card} showVideoChat={showThumbnailLink} annotations={annotationStates.annotations}
                                    videoCount={state.card.thumbnail_count}
                                    setAnnotationStates={setAnnotationStates} callChatApi={annotationStates.callChatApi}
                                    onAnnotationMouseOver={onAnnotationMouseOver} onAnotationMouseOut={onAnotationMouseOut} />
                            </Row>
                        </div>
                    </div>
                </Col>
                    
                {/* </Col> */}
            </Row>
        )
    }

    function showThumbnailImg() {
        const GD_LINK = /http(s)?:\/\/drive\.google\.com\/file\/d\/([^\\]+)\/.*/i;
        let thumbnail_link = currentThumbnailUrl;
        if(GD_LINK.test(thumbnail_link)) {
            let matches = thumbnail_link?.match(GD_LINK);
            let key = "";
            if (loggedInUser.client_type === ADMIN) {
                key = "AIzaSyBAvcPRGkcuWpt7d7bG0PVyj_AN67VUvBc";
            } else if (loggedInUser.client_type === CUSTOMER) {
                key = "AIzaSyBdJXJ3nr3zC6wWJscp767e6ABzv5d0vlc";
            } else {
                key = "AIzaSyD5-jXQ6ReM6YK7mmOKUnXmiPaNgXs4m14";
            }
            if (matches?.length >= 3) {
                return (
                    commonThumbnailImg(`https://www.googleapis.com/drive/v3/files/${matches[2]}?alt=media&key=${key}`)
                );
            }
        } else {
            return (
                commonThumbnailImg(thumbnail_link)
            );
        }
    }

    const handleChangeDisplayThumbnail = (data)=>{
        setShowThumbnailLink(data.video_id);
        setCurrentThumbnailUrl(data.video_url);
    }

    const handleMapThumbnailOption = () => {
        let tempFields = [];
        let videos = state.card.thumbnail_links;
        videos && videos.length > 1 && videos.forEach((data,i) => {
            tempFields.push(
                <div onClick={() => { 
                    handleChangeDisplayThumbnail(data); 
                    setAnnotationStates(INITIAL_ANNOTATION_STATE); 
                }} className={`tab-vid-inn ${showThumbnailLink === data.video_id ? 'active' : ''}`} key={"video-radio-"+data.video_id}>
                    Thumbnail {i+1}
                </div>
            );
        });
        return tempFields;
    }

    const handleMapThumbnailLinks = () => {
        let tempFields = [];
        let thumbnails = card?.thumbnail_links;
        thumbnails && thumbnails.forEach((data, i) => {
            tempFields.push(
                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                    <Col span={isMobile ? 14 : 8} className={styles.iconsAndText}>
                        <img src={RawVideoLinkIcon} className={styles.iconsStyle} alt="ThumbnailLinkIcon" />
                        <Typography.Text className={styles.headingFontStyle}>
                            Thumbnail Link { thumbnails.length > 1 ? i + 1 :null} :
                        </Typography.Text>
                    </Col>
                    <div style={{ display: 'flex', width: "100%", justifyContent: isMobile ? "flex-end" : "flex-start", alignItems: "center"}}>
                        {
                            !isMobile &&
                            <Col span={12} className={styles.deskMode}>
                                <Typography.Text className={styles.greyFontColor}>
                                    <a target="_blank" rel="noopener noreferrer" className={styles.deskMode} href={data.video_url}>{data.video_url}</a>
                                </Typography.Text>
                            </Col>
                        }
                        <Col span={isMobile ? 9 : 2} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            {
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a className={styles.downloadLink} href={data?.video_url} download>
                                    <Button type="primary" icon={<DownloadOutlined />} className={styles.downloadBtn} size={'large'} />
                                </a>
                            }
                        </Col>
                        {
                            loggedInUser.client_type !== CUSTOMER ?
                            <Col span={isMobile ? 9 : 2} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                <div className="btn-side">
                                    {
                                        <Button type="primary" icon={<EditOutlined />} size={'large'} style={{ backgroundColor: '#ab322d' }} className={styles.editOutlinedBtn} tooltip="Change Done Video Link" onClick={() => {
                                            setChangeVideoThumbnailLink({
                                                isEditing: true,
                                                oldUrl: data.video_url,
                                                oldVideoIndex: i,
                                                oldVideoId: data.video_id,
                                                oldUrlType: data.audio_video_type,
                                                newUrlType: data.audio_video_type,
                                            });
                                            setChangeRepurposeVideoUrlState({
                                                isEditing: false
                                            });
                                            setChangeUnlistedVideoUrlState({
                                                isEditing: false
                                            });
                                            }} >
                                        </Button>
                                    }
                                </div>
                            </Col>
                            : null
                        }
                        {isMobile &&
                            <Col span={4} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                <Typography.Text className={styles.greyFontColor}>
                                    <img src={MobileFormatIcon} className={styles.mobileMode} onClick={() => {
                                            setMobileModalData({ isModalOpen: true, title: "Thumbnail Link", modalContent: data.video_url, type: "link" })
                                        }} alt="ThumbnailLinkIcon" />
                                </Typography.Text>
                            </Col>
                        }
                    </div>
                </div>
            );
        });
        return tempFields;
    }

    const handleOkRatings = async () => {
        setIsRatingsModalOpen(false);
        let params = {
            card_id: card?.card_id,
            rating, comment,
            card_type: "yt_helper",
            assigned_qa_id: card.assigned_qa_id,
        }
        const response = await giveRatingToCard(params);
        if (response.status) {
            successToast("Feedback submitted, thank you!");
        } else {
            errorToast("Something went wrong, please try again");
        }
    };

    const handleCancelRatings = () => {
        setIsRatingsModalOpen(false);
    };

    return (
        <div>
            {card ?
                <div>
                    <div className={styles.container}>
                        <div className={styles.leftSection}>
                            <Space className="detailsWrapper">
                                {
                                    loggedInUser.client_type !== CUSTOMER ? <Select
                                        className={`${styles.qaSelectBox} selectBox`}
                                        classNamePrefix="select"
                                        value={card?.yt_card_status === null ? "assigned" : card?.yt_card_status === "inDone" && card?.yt_is_complete === 1 ? "completed" : card?.yt_card_status}
                                        options = {YTHelperStatus}
                                        onChange={e => { setAssignQa(e); setConfirmationOpen(true); handleDropdownItemClick(e, card) }}>
                                    </Select> : null
                                }

                            </Space>
                            {
                                card?.yt_timeline ?
                                    <Space className="detailsWrapper">
                                        <img src={TimelineIcon} className={styles.iconsStyle} alt="TimelineIcon" />
                                        <Typography.Text className={styles.greyFontColor}>
                                            <span>Timeline: </span>{card?.yt_timeline}hrs
                                        </Typography.Text>
                                    </Space> : null
                            }
                            {
                                card?.yt_card_revision ?
                                    <Space className="detailsWrapper">
                                        <img src={TimelineIcon} className={styles.iconsStyle} alt="TimelineIcon" />
                                        <Typography.Text className={styles.greyFontColor}>
                                            <span>Revisions: </span>{card?.yt_card_revision}
                                        </Typography.Text>
                                    </Space> : null
                            }
                            <Space className="detailsWrapper">
                                <img src={TimelineIcon} className={styles.iconsStyle} alt="TimelineIcon" />
                                <Typography.Text className={styles.greyFontColor}>
                                    <span>Editor Status: </span>{card?.card_status === DONE && card?.yt_helper_status && card?.role === YT_HELPER && card?.is_complete ? "Ready For Upload" :
                                    card?.is_complete ? "Mark as done completed" : getCardDisplayStatus(card?.card_status)}
                                </Typography.Text>
                            </Space>
                        </div>
                        <div className={styles.rightSection}>                    
                            {
                                !(!(isCustomer(loggedInUser) || isAdmin(loggedInUser)) 
                                    || (card?.yt_card_status !== YT_HELPER_IN_DONE) 
                                    || (card?.yt_is_complete)) &&
                                        <Button type="primary" size={'large'} className={styles.revisionBtn} onClick={() => setIsRevisionModalOpen(true)}>Revision</Button>
                            }
                            {
                                !isMobile && markAsDoneButton()
                            }
                            {
                                card?.yt_is_complete
                                    ?
                                    <Button type="primary" size={'large'} className={styles.readyForUploadBtn} onClick={() => setIsRatingsModalOpen(true)}>
                                        Rate
                                    </Button>
                                    : null
                            }
                        </div>
                        {isMobile &&
                            <div className={styles.mobileMarkDoneBtn}>
                                {markAsDoneButton()}
                            </div>
                        }
                    </div>
                    <Divider className="detailsTabDivider" />
                    <Row gutter={[16, 16]} style={{ marginRight: 0 }}>
                        <div className={styles.assignedToWrapper} style={{display: "flex", width: "100%"}}>
                            <Col span={isMobile ? 10 : 8} className={styles.iconsAndText}>
                                <img src={AssignedToIcon} className={styles.iconsStyle} alt="AssignedToIcon" />
                                <Typography.Text className={styles.headingFontStyle}>
                                    Assigned to:
                                </Typography.Text>
                            </Col>
                            {isMobile &&
                                    makeQaAndDueDateRow()
                            }
                            <Col span={isMobile ? 6 : 16}  className={styles.colStyle} style={{ justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                <Tooltip title={
                                            card?.client_firstname || card?.client_lastname ? `${card?.client_firstname || ''} ${card?.client_lastname || ''}` : 'N/A'
                                        } placement="top">
                                    <Avatar className={styles.avatarStyle} style={{ backgroundColor: '#f56a00' }}>
                                        {card?.client_firstname || card?.client_lastname ?
                                            `${card?.client_firstname.toUpperCase().charAt(0)}${card?.client_lastname.toUpperCase().charAt(0)}` : 'N/A'}
                                    </Avatar>
                                </Tooltip>
                                <Tooltip title={
                                            card?.yt_firstname || card?.yt_lastname ? getUserShortName(`${card?.yt_firstname || ''} ${card?.yt_lastname || ''}`) : 'N/A'
                                        } placement="top">
                                    <Avatar className={styles.avatarStyle} style={{ backgroundColor: '#87d068' }}>
                                        {card?.qa_firstname || card?.qa_lastname ? 
                                            `${card?.yt_firstname?.toUpperCase().charAt(0)}${card?.yt_lastname?.toUpperCase().charAt(0)}` : 'N/A'}
                                    </Avatar>
                                </Tooltip>
                                {!isMobile &&
                                    makeQaAndDueDateRow()
                                }
                            </Col>
                        </div>
                        {makeDueDateRow()}
                        {
                            isMobile && ((state.dueDate  && ((viewType !== "yt-helper" && cardInProgress(state.card) )
                            || (viewType === "yt-helper" && state.card.ytHelper_card_status === YT_HELPER_IN_PROGRESS ))) || (state.card?.card_status === VIDEO_REQUEST && state.card.subscription_type === WEEKLY)) &&
                                <Col span={2} style={{ paddingLeft: 0, alignSelf: 'center', marginLeft: 'auto' }}>
                                    <Typography.Text className={styles.greyFontColor}>
                                        <img src={MobileCalenderIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Due on", modalContent: updatedDueDate || viewType === "yt-helper" ? card?.convertedYTHelperTime : card?.convertedTime })
                                                }} alt="AspectRatioIcon" />
                                    </Typography.Text>
                                </Col>
                        }
                        {
                            card?.video_thumb_desc &&
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                        <img src={VideoInfoIcon} className={styles.iconsStyle} alt="VideoInfoIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Instructions about thumbnail:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 2 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={`${styles.greyFontColor}`}>
                                            <img src={MobileEyeIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Instructions about thumbnail", modalContent: parse(card?.video_thumb_desc) })
                                                }} alt="AspectRatioIcon" />
                                            <div className={styles.deskMode}>{parse(card?.video_thumb_desc)}</div>
                                        </Typography.Text>
                                    </Col>
                                </div>
                        }
                        {
                            card?.video_thumb_ref &&
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                        <img src={VideoInfoIcon} className={styles.iconsStyle} alt="VideoInfoIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Video Thumbnail Reference Links:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 2 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={`${styles.greyFontColor}`}>
                                            <img src={MobileEyeIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Video Thumbnail Reference Links", modalContent: card?.video_thumb_ref })
                                                }} alt="AspectRatioIcon" />
                                            <div className={styles.deskMode}>{card?.video_thumb_ref}</div>
                                        </Typography.Text>
                                    </Col>
                                </div>
                        }
                        {
                            card?.video_optimization_desc &&
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                        <img src={VideoInfoIcon} className={styles.iconsStyle} alt="VideoInfoIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Instruction about video title and description:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 2 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={`${styles.greyFontColor}`}>
                                            <img src={MobileEyeIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Instruction about video title and description", modalContent: parse(card?.video_optimization_desc) })
                                                }} alt="AspectRatioIcon" />
                                            <div className={styles.deskMode}>{parse(card?.video_optimization_desc)}</div>
                                        </Typography.Text>
                                    </Col>
                                </div>
                        }
                        {
                            card?.affiliate_links &&
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                        <img src={VideoInfoIcon} className={styles.iconsStyle} alt="VideoInfoIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Affiliate or Product Links:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 2 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={`${styles.greyFontColor}`}>
                                            <img src={MobileEyeIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Affiliate or Product Links", modalContent: card?.affiliate_links })
                                                }} alt="AspectRatioIcon" />
                                            <div className={styles.deskMode}>{card?.affiliate_links}</div>
                                        </Typography.Text>
                                    </Col>
                                </div>
                        }
                        {
                            card.yt_card_description &&
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                        <img src={VideoInfoIcon} className={styles.iconsStyle} alt="VideoInfoIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Video Description:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 2 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={`${styles.greyFontColor}`}>
                                            <img src={MobileEyeIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Video Description", modalContent: parse(card?.yt_card_description) })
                                                }} alt="AspectRatioIcon" />
                                            <div className={styles.deskMode}>{parse(card?.yt_card_description)}</div>
                                        </Typography.Text>
                                    </Col>
                                </div>
                        }
                        {
                            [
                                card.yt_card_status === "inDone" ?
                                    <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                        <Col span={isMobile ? 14 : 8} className={styles.iconsAndText}>
                                            <img src={RawVideoLinkIcon} className={styles.iconsStyle} alt="UnlistedVideoLinkIcon" />
                                            <Typography.Text className={styles.headingFontStyle}>
                                                Unlisted video:
                                            </Typography.Text>
                                        </Col>
                                        <div style={{ display: 'flex', width: "100%", justifyContent: isMobile ? "flex-end" : "flex-start", alignItems: "center"}}>
                                            {
                                                !isMobile &&
                                                <Col span={12} className={styles.deskMode}>
                                                    <Typography.Text className={styles.greyFontColor}>
                                                        <a target="_blank" rel="noopener noreferrer" className={styles.deskMode} href={card.unlisted_video_link}>{card.unlisted_video_link || "N/A"}</a>
                                                    </Typography.Text>
                                                </Col>
                                            }
                                            <Col span={isMobile ? 9 : 2} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                {
                                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                    <a className={styles.downloadLink} href={card?.unlisted_video_link} download>
                                                        <Button type="primary" icon={<DownloadOutlined />} className={styles.downloadBtn} size={'large'} />
                                                    </a>
                                                }
                                            </Col>
                                            {
                                                loggedInUser.client_type === ADMIN || loggedInUser.client_type === YT_HELPER || loggedInUser.client_type === YT_HELPER_LEAD || loggedInUser.client_type === TEAM_LEAD ?
                                                <Col span={isMobile ? 9 : 2} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                    <div className="btn-side">
                                                        {
                                                            <Button type="primary" icon={<EditOutlined />} size={'large'} style={{ backgroundColor: '#ab322d' }} className={styles.editOutlinedBtn} tooltip="Change Unlisted Video Link" onClick={() => {
                                                                setChangeUnlistedVideoUrlState({
                                                                    isEditing: true,
                                                                    oldUrl: card.unlisted_video_link,
                                                                });
                                                                setChangeDoneVideoUrlState({
                                                                    isEditing: false
                                                                });
                                                                setChangeRepurposeVideoUrlState({
                                                                    isEditing: false
                                                                });
                                                                }} >
                                                            </Button>
                                                        }
                                                    </div>
                                                </Col>
                                                : null
                                            }
                                            {isMobile &&
                                                <Col span={4} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                    <Typography.Text className={styles.greyFontColor}>
                                                        <img src={MobileFormatIcon} className={styles.mobileMode} onClick={() => {
                                                                setMobileModalData({ isModalOpen: true, title: "Unlisted Video Link", modalContent: card.unlisted_video_link || "N/A", type: "link" })
                                                            }} alt="UnlistedVideoLinkIcon" />
                                                    </Typography.Text>
                                                </Col>
                                            }
                                        </div>
                                    </div> : null,
                                    (card?.thumbnails_link || card?.thumbnail_links.length > 0) ? handleMapThumbnailLinks() : null,
                                card.yt_snippet_link ?
                                    <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                        <Col span={isMobile ? 14 : 8} className={styles.iconsAndText}>
                                            <img src={RawVideoLinkIcon} className={styles.iconsStyle} alt="UnlistedVideoLinkIcon" />
                                            <Typography.Text className={styles.headingFontStyle}>
                                                Snippet Link:
                                            </Typography.Text>
                                        </Col>
                                        <div style={{ display: 'flex', width: "100%", justifyContent: isMobile ? "flex-end" : "flex-start", alignItems: "center"}}>
                                            {
                                                !isMobile &&
                                                <Col span={12} className={styles.deskMode}>
                                                    <Typography.Text className={styles.greyFontColor}>
                                                        <a target="_blank" rel="noopener noreferrer" className={styles.deskMode} href={card?.yt_snippet_link}>{card?.yt_snippet_link || "N/A"}</a>
                                                    </Typography.Text>
                                                </Col>
                                            }
                                            <Col span={isMobile ? 9 : 2} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                {
                                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                    <a className={styles.downloadLink} href={card?.yt_snippet_link} download>
                                                        <Button type="primary" icon={<DownloadOutlined />} className={styles.downloadBtn} size={'large'} />
                                                    </a>
                                                }
                                            </Col>
                                            {
                                                loggedInUser.client_type === ADMIN || loggedInUser.client_type === YT_HELPER || loggedInUser.client_type === YT_HELPER_LEAD || loggedInUser.client_type === TEAM_LEAD ?
                                                <Col span={isMobile ? 9 : 2} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                    <div className="btn-side">
                                                        {
                                                            <Button type="primary" icon={<EditOutlined />} size={'large'} style={{ backgroundColor: '#ab322d' }} className={styles.editOutlinedBtn} tooltip="Change Snippet Link" onClick={() => {
                                                                setChangeSnippetVideoLink({
                                                                    isEditing: true,
                                                                    oldUrl: card.yt_snippet_link,
                                                                });
                                                                setChangeDoneVideoUrlState({
                                                                    isEditing: false
                                                                });
                                                                setChangeRepurposeVideoUrlState({
                                                                    isEditing: false
                                                                });
                                                                setChangeUnlistedVideoUrlState({
                                                                    isEditing: false
                                                                });
                                                                setChangeVideoThumbnailLink({
                                                                    isEditing: false
                                                                });
                                                                }} >
                                                            </Button>
                                                        }
                                                    </div>
                                                </Col>
                                                : null
                                            }
                                            {isMobile &&
                                                <Col span={4} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                    <Typography.Text className={styles.greyFontColor}>
                                                        <img src={MobileFormatIcon} className={styles.mobileMode} onClick={() => {
                                                                setMobileModalData({ isModalOpen: true, title: "Snippet Link", modalContent: card.thumbnails_link || "N/A", type: "link" })
                                                            }} alt="SnippetVideoLinkIcon" />
                                                    </Typography.Text>
                                                </Col>
                                            }
                                        </div>
                                    </div> : null
                            ]
                        }
                        {
                            changeUnlistedVideoUrlState.isEditing ?
                                <>
                                    {!isMobile &&
                                        <Col span={8}>
                                        </Col>
                                    }
                                    <Col span={isMobile ? 24 : 16} className={styles.wrapperForLinkChange}>
                                            <TextInput label="New Video Link" className={styles.newLinkField}
                                                value={changeUnlistedVideoUrlState.newVideoLink === '' ? '' : changeUnlistedVideoUrlState.newVideoLink || changeUnlistedVideoUrlState.oldUrl} validate
                                                onChange={(e) => {
                                                    let value = e.target.value; setChangeUnlistedVideoUrlState((prev) => ({ ...prev, newVideoLink: value }))
                                                }} />

                                            <Button type="primary" icon={<CloseOutlined />} size={'large'} className={styles.closeBtn} key="cancel-btn-change-unlisted-video" tooltip="Cancel" onClick={() =>
                                                setChangeUnlistedVideoUrlState({ isEditing: false })} />
                                            <Button type="primary" icon={<SaveOutlined />} size={'large'} className={styles.saveBtn} key="save-change-unlisted-video" tooltip="Save Unlisted Video Link" onClick={e =>
                                                handleDoneVideoChange()} />
                                    </Col>
                                </>
                            : null
                        }
                        {
                            changeVideoThumbnailLink.isEditing ?
                                <>
                                    {!isMobile &&
                                        <Col span={8}>
                                        </Col>
                                    }
                                    <Col span={isMobile ? 24 : 16} className={styles.wrapperForLinkChange}>
                                            <TextInput label="New Video Link" className={styles.newLinkField}
                                                value={changeVideoThumbnailLink.newVideoLink === '' ? '' : changeVideoThumbnailLink.newVideoLink || changeVideoThumbnailLink.oldUrl} validate
                                                onChange={(e) => {
                                                    let value = e.target.value; setChangeVideoThumbnailLink((prev) => ({ ...prev, newVideoLink: value }))
                                                }} />

                                            <Button type="primary" icon={<CloseOutlined />} size={'large'} className={styles.closeBtn} key="cancel-change-video-thumbnail-link" tooltip="Cancel" onClick={() =>
                                                setChangeVideoThumbnailLink({ isEditing: false })} />
                                            <Button type="primary" icon={<SaveOutlined />} size={'large'} className={styles.saveBtn} key="cancel-change-video-thumbnail-link" tooltip="Save Video Thumbnail Link" onClick={e =>
                                                handleDoneVideoChange()} />
                                    </Col>
                                </>
                            : null
                        }
                        {
                            changeSnippetVideoLink.isEditing ?
                                <>
                                    {!isMobile &&
                                        <Col span={8}>
                                        </Col>
                                    }
                                    <Col span={isMobile ? 24 : 16} className={styles.wrapperForLinkChange}>
                                            <TextInput label="New Video Link" className={styles.newLinkField}
                                                value={changeSnippetVideoLink.newVideoLink === '' ? '' : changeSnippetVideoLink.newVideoLink || changeSnippetVideoLink.oldUrl} validate
                                                onChange={(e) => {
                                                    let value = e.target.value; setChangeSnippetVideoLink((prev) => ({ ...prev, newVideoLink: value }))
                                                }} />

                                            <Button type="primary" icon={<CloseOutlined />} size={'large'} className={styles.closeBtn} key="cancel-change-snippet-link" tooltip="Cancel" onClick={() =>
                                                setChangeSnippetVideoLink({ isEditing: false })} />
                                            <Button type="primary" icon={<SaveOutlined />} size={'large'} className={styles.saveBtn} key="save-change-snippet-link" tooltip="Save Snippet Link" onClick={e =>
                                                handleDoneVideoChange()} />
                                    </Col>
                                </>
                            : null
                        }
                        {
                            state?.card?.thumbnail_links?.length > 1 && 
                                <Col span={24} className={`tab-vid ${styles.videoTabs}`}>
                                    {
                                        handleMapThumbnailOption()
                                    }
                                </Col>
                        }
                        {
                            state.card.thumbnails_link && showThumbnailImg()
                        }
                        {
                            state.card.card_id && showLargeChat ? 
                                <>
                                    {
                                        !isMobile ? 
                                            <Col span={14}>
                                            </Col>
                                         : null
                                    }
                                    <Col span={isMobile ? 24 : 10}  className={styles.rightSection}>
                                        <div className={styles.chatBoxWrapper}>
                                            <div>
                                                <Row className="chatbox ythelperChatBox">
                                                    <ChatBox cardId={card.card_id} me={loggedInUser} poll={true} section={YT_HELPER_SECTION} card={state.card} />
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            :''
                        }
                        <MobileModal
                            mobileModalData={mobileModalData}
                            clearFields={clearFields}
                            handleCancel={handleCancelMobileModal}
                            type={type} />
                        <YTHelperCommonModal
                            title={"Revision"}
                            isModal={isRevisionModalOpen}
                            handleOk={handleOkRevision}
                            handleCancel={handleCancelRevision}
                            popUpMessage={`Requesting a revision for: ${card?.card_title}. Are you sure you want to proceed?`}
                            rating={rating}
                            setRating={setRating}
                            comment={comment}
                            setComment={setComment}
                            cardId={card?.card_id}
                            />
                        <YTHelperCommonModal
                            title={"Mark As Done"}
                            isModal={isMarkDoneModalOpen}
                            handleOk={handleOkMarkDone}
                            handleCancel={handleCancelMarkDone}
                            popUpMessage={`Mark ${card?.card_title} as done?`}
                            rating={rating}
                            setRating={setRating}
                            comment={comment}
                            setComment={setComment}
                            cardId={card?.card_id}
                            />
                        <ConfirmationDialog
                            onNegative ={()=> {setMoveCardInProgress({open:false});}}
                            onPositive ={async ()=> {
                                await moveYtHelperCardInProgress(moveCardInProgress.cardId, moveCardInProgress.newStatus,moveCardInProgress.cardStatus,() => {});
                                updateCardDetail();
                            }}
                            confirmationHeader = "Confirm"
                            confirmationDialogId = {"confirm-ythelper-card-move"}
                            confirmation = {moveCardInProgress.open}
                            confirmationText = {"Are you sure you want to move the card for editing section?"}
                        />
                        <ConfirmationDialog
                            onNegative ={()=> {setMoveCardInCompleted({open:false});}}
                            onPositive ={async ()=> {
                                await markYtCardAsCompleted(moveCardInCompleted.cardId, moveCardInCompleted.newStatus, moveCardInCompleted.cardStatus,() => {});
                                updateCardDetail();
                            }}
                            confirmationHeader = "Confirm"
                            confirmationDialogId = {"confirm-ythelper-card-move"}
                            confirmation = {moveCardInCompleted.open}
                            confirmationText = {"Are you sure you want to mark the card as completed?"}
                        />
                        <YTHelperCardMovementModal
                            setYtHelperAssetsModal={setYtHelperAssetsModal}
                            ytHelperAssetsModal={ytHelperAssetsModal}
                            thumbnailsLink={thumbnailsLink}
                            setThumbnailsLink={setThumbnailsLink}
                            videoDescription={videoDescription}
                            setVideoDescriptions={setVideoDescriptions}
                            snippetLink={snippetLink}
                            setSnippetLink={setSnippetLink}
                            unlistedLink={unlistedLink}
                            setUnlistedLink={setUnlistedLink}
                            editorState={editorState}
                            setEditorState={setEditorState}
                            updateCardDetail={updateCardDetail}
                            setThumbnailLinkUrlArray={setThumbnailLinkUrlArray}
                            thumbnailLinkUrlArray={thumbnailLinkUrlArray}
                            thumbnailCount={state?.card?.thumbnail_count}
                            />
                        <ConfirmationDialog
                            onNegative={() => setConfirmationYtCard({open: false}) }
                            onPositive={() => {
                                moveYtCard(confirmationYtCard.cardId, confirmationYtCard.newStatus, confirmationYtCard.cardStatus, () => {});
                                updateCardDetail();
                            }}
                            confirmationHeader="Confirm"
                            confirmationDialogId={"confirm-move"}
                            confirmation={confirmationYtCard.open}
                            confirmationText={"Are you sure you want to move the card?"}
                        />
                        <Modal className={'modalStyle'} title="Feedback" open={isRatingsModalOpen} onOk={handleOkRatings} onCancel={handleCancelRatings}
                         footer={[
                            <Button key="back" onClick={handleCancelRatings}>
                              Cancel
                            </Button>,
                            <Button key="submit" type="primary" disabled={rating === 0} onClick={handleOkRatings}>
                              OK
                            </Button>
                          ]}>
                            <div className="ratingDiv">
                                <Ratings
                                    rating={card?.rating || rating}
                                    widgetHoverColors={primaryColor}
                                    widgetEmptyColors={disableColor}
                                    widgetRatedColors={primaryColor} 
                                    changeRating={setRating}
                                >
                                    <Ratings.Widget/>
                                    <Ratings.Widget/>
                                    <Ratings.Widget/>
                                    <Ratings.Widget/>
                                    <Ratings.Widget/>
                                </Ratings>
                                <TextInput s={12} label="Any feedback?" icon="short_text" value={comment}
                                    id={"txt-feedback-" + card?.card_id}
                                    onChange={e => setComment(e.target.value)}/>
                            </div>
                        </Modal>
                    </Row>
                </div>
                : <Spin className={styles.loader} size="large" spinning={card ? false : true} />
            }
        </div>
    );
}

export default YTHelperTab;
