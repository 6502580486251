import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Checkbox,
    Col,
    Container,
    DatePicker,
    Divider,
    Icon,
    Row,
    Select,
    Textarea,
    TextInput,
    Tab,
    Tabs,
} from "react-materialize";
import axios from "axios";
import {axiosClient, setUserTimezoneInRequest} from "../../api/httpClient";
import {connect} from "react-redux";
import {
    EMAIL_REGEX,
    errorToast,
    infoToast,
    isCustomer,
    isMobile,
    modifiedOrNull,
    parseSqlDateTime,
    resize,
    successToast,
    toISODate,
    uploadMedia,
    validateEmail,
    warningToast
} from "../../api/Util";
import * as _ from "lodash";
import * as actions from "../../actions";
import {randomNumber} from "react-ratings-declarative/build/utils";
import Profile from '../img/profile.png';
import BillingTable from "../common/BillingTable/BillingTable";

import {Prompt} from 'react-router-dom';
import { MdErrorOutline } from "react-icons/md";
import { CARD_COMMENT_EMAIL, CARD_COMMENT_TAG_EMAIL, CARD_MOVEMENT_EMAIL, CUSTOMER, INITIAL_EMAIL_PREFERENCES_STATE, TIMEZONE_LIST } from "../../api/Constants";
import styles from './Profile.module.scss';
import './Profile.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CustomLoginButton from "../CustomerLoginButton/CustomLoginButton";
import YTAnalyticsTable from "../common/YTAnalyticsTable/YTAnalyticsTable";

const INITIAL_STATE = {
    firstName: "",
    lastName: "",
    email: "",
    secondaryEmail: "",
    dropboxEmail: "",
    password: "",
    referral: "Other",
    otherReferral: "",
    videoType: "",
    aboutVideo: "",
    socialMediaLink: "",
    videoStyle: "",
    videoInspired: "",
    videoInfo: "",
    additionalVideoInfo: "",
    billingDate: new Date(0),
    billingData: [],
    preferredEditingSoftware: "",
    userProfile: {},
    
    channelName:"",
    prevThumbnailsLink:[""],
    refThumbnailsFile:"",
    thumbnailFont:"",
    thumbnailMainColor:"",
    videoPublishingSchedules: "",
    thumbnailSubColor:"",
    descriptionLink:[""],
    photoPreference:"",
    textPreference:"",
    titlePreference:"",
    endCardPreference:"",
    publicVideo:"",
    keywordList:"",
    additionalThumbnailData:"",
    showProtalVideo:0,
    ytAnalyticsData: [],
    customerId: "",
};

const INITIAL_STATE_FOR_TEST = {
    firstName: null,
    lastName: null,
    email: null,
    secondaryEmail: null,
    dropboxEmail: null,
    password: null,
    referral: null,
    otherReferral: null,
    videoType: null,
    aboutVideo: null,
    socialMediaLink: null,
    videoStyle: null,
    videoInspired: null,
    videoInfo: null,
    additionalVideoInfo: null,
    billingDate: null,
    billingData: [],
    preferredEditingSoftware: null,
    timezone: "",
    channelName:null,
    prevThumbnailsLink:null,
    refThumbnailsFile:null,
    thumbnailFont:null,
    thumbnailMainColor:null,
    thumbnailSubColor:null,
    descriptionLink:null,
    photoPreference:null,
    textPreference:null,
    titlePreference:null,
    endCardPreference:null,
    publicVideo:null,
    keywordList:null,
    additionalThumbnailData:null,
    ytAnalyticsData: [],
    clientId: "",
};

export default connect(mapStateToProps, actions)((props) => {

    const loggedInUser = props?.auth?.loggedInUser || {};
    const customer = isCustomer(loggedInUser);
    const incompleteProfile = loggedInUser.is_temporary_password || !loggedInUser.is_profile_completed;
    const [state, setState] = useState(INITIAL_STATE);
    const [updateDisabled, setUpdateDisabled] = useState(false);
    const [profileImage, setProfileImage] = useState(null);

    const [selectedFile, setSelectedFile] = useState(null);
    const [showThumbnailsPre, setShowThumbnailsPre] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [activeTab, setActiveTab] = useState('BASIC INFO');
    const [showGoogleLogin, setShowGoogleLogin] = useState(true);

    const [changedData, setChangedData] = useState(INITIAL_STATE_FOR_TEST);

    let showYtField = false;
    if(customer && loggedInUser.has_youtube_helper && loggedInUser.is_yt_fields_filled)
    {
        showYtField = true;
    }

    useEffect(() => {
        if (state.showProtalVideo === 1 && incompleteProfile) {
            let video = document.querySelector('#profile-portal-vid');
            if (video) {
                video.play();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.showProtalVideo])
    
    const refreshUser = () => {
        infoToast("Loading");
        setChangedData(INITIAL_STATE_FOR_TEST);
        const cancelToken = axios.CancelToken.source();
        axiosClient.get('/api/user/' + loggedInUser.client_id, {cancelToken: cancelToken.token})
            .then(({data}) => {
                setState(prev => ({
                    ...prev,
                    firstName: data.firstname,
                    lastName: data.lastname,
                    email: data.useremail,
                    secondaryEmail: data.secondary_email || prev.secondaryEmail,
                    dropboxEmail: data.dropbox_email || prev.dropboxEmail,
                    referral: data.referral || prev.referral,
                    otherReferral: data.otherreferral || prev.otherReferral,
                    videoType: data.video_type || prev.videoType,
                    aboutVideo: data.about_video || prev.aboutVideo,
                    socialMediaLink: data.social_media_link || prev.socialMediaLink,
                    videoStyle: data.video_style || prev.videoStyle,
                    videoInspired: data.video_inspired || prev.videoInspired,
                    videoInfo: data.video_info || prev.videoInfo,
                    additionalVideoInfo: data.additional_video_info || prev.additionalVideoInfo,
                    billingDate: parseSqlDateTime(data.billing_date),
                    billingData: data.billing_data,
                    preferredEditingSoftware: data.preferred_editing_software || "",
                    timezone: data.timezone || "",

                    // for yt helper fields data
                    channelName: data.channel_name || prev.channelName,
                    prevThumbnailsLink: 
                    data.prev_thumbnails_link && data.prev_thumbnails_link !== "" ? data.prev_thumbnails_link.split('|'):[""] ,
                        // || prev.prevThumbnailsLink.split(','),
                    refThumbnailsFile: data.ref_thumbnails_file || prev.refThumbnailsFile,
                    thumbnailFont: data.thumbnail_font || prev.thumbnailFont,
                    thumbnailMainColor: data.thumbnail_main_color || prev.thumbnailMainColor,
                    videoPublishingSchedules: data.video_publishing_schedules || prev.video_publishing_schedules,
                    thumbnailSubColor: data.thumbnail_sub_color || prev.videoInspired,
                    descriptionLink: data.description_link && data.description_link !== "" ? data.description_link.split('|'):[""] ,
                        // || prev.descriptionLink.split(','),
                    photoPreference: data.photo_preference || prev.photoPreference,
                    textPreference: data.text_preference || prev.textPreference,
                    titlePreference: data.title_preference || prev.titlePreference,
                    endCardPreference: data.end_card_preference || prev.endCardPreference,
                    publicVideo: data.public_video || prev.publicVideo,
                    keywordList:data.keyword_list || prev.keywordList,
                    additionalThumbnailData:data.additional_thumbnail_data || prev.additionalThumbnailData,
                    showProtalVideo: data.show_portal_video ?? 0,

                    userProfile: data,
                    ytAnalyticsData: data.yt_analytics_data,
                    customerId: data.client_id,
                }));
                setProfileImage(data.profile_img);
                setState(prev => ({...prev, preferredEditingSoftware: data.preferred_editing_software || ""}));
                if(data.ref_thumbnails_file && data.ref_thumbnails_file !== "")
                {
                    setShowThumbnailsPre(true);
                }
                if (data.mailPreferences) {
                    setState(prev => ({...prev, mailPreferences: data.mailPreferences}));
                }
                if (data?.access_token?.access_token || !customer) {
                    setShowGoogleLogin(false);
                }
            }).catch(err => {
                if (!axios.isCancel(err)) {
                    errorToast("Something went wrong");
                    console.error(err);
                }
            });

        return cancelToken;
    };

    useEffect(() => {
        const cancelToken = refreshUser();

        return () => cancelToken.cancel("Page closed");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser, customer, incompleteProfile]);

    useEffect(() => {
        if(Object.keys(changedData).find(key => changedData[key] === 1)?true:false)
        {
            window.onbeforeunload = (event) => {
                const e = event || window.event;
                // Cancel the event
                e.preventDefault();
                if (e) {
                  e.returnValue = "There are unsaved changes. Are you sure want to leave this page?"; // Legacy method for cross browser support
                }
                return "There are unsaved changes. Are you sure want to leave this page?"; // Legacy method for cross browser support
              };
        }
        else{
            window.onbeforeunload = (event) => {
                // const e = event || window.event;
                // Cancel the event
                // e.preventDefault();
            }
        }
    }, [changedData]);
    
    useEffect(() => {
        return ()=>{
            window.onbeforeunload = (event) => {
                // const e = event || window.event;
                // Cancel the event
                // e.preventDefault();
            }
        }
    }, [])

    useEffect(() => {
        if(showYtField && loggedInUser.is_yt_fields_filled === 1 && document.getElementById("yt-helper-field")){
            var elmnt = document.getElementById("yt-helper-field");
            elmnt.scrollIntoView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onClickUpdate() {

        if(incompleteProfile && !state.dropboxEmail)
        {
            return infoToast("Please fill the required Gdrive or Dropbox Email field ");
        }
        if(incompleteProfile && state.dropboxEmail && !EMAIL_REGEX.test(state.dropboxEmail))
        {
            return infoToast("Please fill the valid Gdrive or Dropbox Email address in the field");
        }
        if(showYtField && (state.channelName === "" || state.thumbnailFont === "" || state.thumbnailMainColor === "" || state.descriptionLink[0] === "" || state.photoPreference === "" )){
            if(state.channelName === "") {
                document.getElementById('channelName-error-msg').classList.remove("error-hidden");
                document.getElementById('channelName-error-msg').classList.add("show-error");
            }
            if(state.thumbnailFont === "") {
                document.getElementById('thumbnailFont-error-msg').classList.remove("error-hidden");
                document.getElementById('thumbnailFont-error-msg').classList.add("show-error");
            }
            if(state.thumbnailMainColor === "") {
                document.getElementById('thumbnailMainColor-error-msg').classList.remove("error-hidden");
                document.getElementById('thumbnailMainColor-error-msg').classList.add("show-error");
            }
            if(state.descriptionLink[0] === "") {
                document.getElementById('descriptionLink-error-msg').classList.remove("error-hidden");
                document.getElementById('descriptionLink-error-msg').classList.add("show-error");
            }
            if(state.photoPreference === "") {
                document.getElementById('photoPreference-error-msg').classList.remove("error-hidden");
                document.getElementById('photoPreference-error-msg').classList.add("show-error");
            }
            return warningToast("Please fill the required field");
        }

        let temp = true;
        const secondaryEmailArray = state.secondaryEmail.split(', ');
        secondaryEmailArray.map(item => {
            const result = validateEmail(item);
            if (!result && state.secondaryEmail) {
                errorToast("Invalid Assitant Email Address")
                temp = false;
                return false;
            }
        })
        if (!temp) {
            return;
        }

        setUpdateDisabled(true);
        let data = {
            firstname: modifiedOrNull(state.firstName, state.userProfile.firstname),
            lastname: modifiedOrNull(state.lastName, state.userProfile.lastname),
            email: modifiedOrNull(state.email, state.userProfile.useremail),
            secondary_email: modifiedOrNull(state.secondaryEmail, state.userProfile.secondary_email),
            clientType: state.userProfile.client_type,
            dropbox_email: modifiedOrNull(state.dropboxEmail, state.userProfile.dropbox_email),
            password: (state.password != null && state.password !== "") ? state.password : null,
            referral: modifiedOrNull(state.referral, state.userProfile.referral),
            otherreferral: modifiedOrNull(state.otherReferral, state.userProfile.otherreferral) || state.userProfile.otherreferral,
            video_type: modifiedOrNull(state.videoType, state.userProfile.video_type),
            about_video: modifiedOrNull(state.aboutVideo, state.userProfile.about_video),
            social_media_link: modifiedOrNull(state.socialMediaLink, state.userProfile.social_media_link),
            video_style: modifiedOrNull(state.videoStyle, state.userProfile.video_style),
            video_inspired: modifiedOrNull(state.videoInspired, state.userProfile.video_inspired),
            video_info: modifiedOrNull(state.videoInfo, state.userProfile.video_info),
            additional_video_info: modifiedOrNull(state.additionalVideoInfo, state.userProfile.additional_video_info),
            billing_date: modifiedOrNull(toISODate(state.billingDate), state.userProfile.billing_date),
            preferred_editing_software: modifiedOrNull(state.preferredEditingSoftware, state.userProfile.preferred_editing_software),
            timezone: modifiedOrNull(state.timezone, state.userProfile.timezone),

            // for yt helper fields data
            channel_name: modifiedOrNull(state.channelName, state.userProfile.channel_name),
            prev_thumbnails_link: modifiedOrNull(state.prevThumbnailsLink, state.userProfile.prev_thumbnails_link),
            ref_thumbnails_file: modifiedOrNull(state.refThumbnailsFile, state.userProfile.ref_thumbnails_file),
            thumbnail_font: modifiedOrNull(state.thumbnailFont, state.userProfile.thumbnail_font),
            thumbnail_main_color: modifiedOrNull(state.thumbnailMainColor, state.userProfile.thumbnail_main_color),
            thumbnail_sub_color: modifiedOrNull(state.thumbnailSubColor, state.userProfile.thumbnail_sub_color),
            description_link: modifiedOrNull(state.descriptionLink, state.userProfile.description_link),
            photo_preference: modifiedOrNull(state.photoPreference, state.userProfile.photo_preference),
            text_preference: modifiedOrNull(state.textPreference, state.userProfile.text_preference),
            title_preference: modifiedOrNull(state.titlePreference, state.userProfile.title_preference),
            end_card_preference: modifiedOrNull(state.endCardPreference, state.userProfile.end_card_preference),
            public_video: modifiedOrNull(state.publicVideo, state.userProfile.public_video),
            keyword_list:modifiedOrNull(state.keywordList, state.userProfile.keyword_list),
            additional_thumbnail_data:modifiedOrNull(state.additionalThumbnailData, state.userProfile.additional_thumbnail_data),
        };

        if (state.mailPreferences) {
            data = {...data, mailPreferences: state.mailPreferences};
        }

        data = _.pickBy(data, _.identity);

        if (state.showProtalVideo !== state.userProfile.show_portal_video) {
            data = {...data, show_portal_video:state.showProtalVideo};
        }

        data = {...data, video_publishing_schedules: modifiedOrNull(state.videoPublishingSchedules, state.userProfile.video_publishing_schedules)}

        setChangedData(INITIAL_STATE_FOR_TEST);
        
        axiosClient.post('/api/user/profile', data).then(({data}) => {
            let timezone = modifiedOrNull(state.timezone, state.userProfile.timezone)
            if (timezone) {
                localStorage.setItem("timezone", timezone);
            }

            setUserTimezoneInRequest();
            successToast(data.message || "Profile updated successfully");

            localStorage.removeItem("skipProfileForNow");

            if(incompleteProfile || loggedInUser.is_yt_fields_filled === 1) {
                props.fetchUser();
                setTimeout(props.history.push("/"), 1000);
            }
        }).catch(err => {
            errorToast("Unable to update profile");
            console.error(err);
        }).finally(() => {
            setUpdateDisabled(false);
            props.history.push('/');
        });
    }

    function onChange(e) {
        let name = e.target.name;
        let value = e.target.value;

        let tem = "";
        let temValue = value === "" ? (name==="otherReferral"?"":null):value;

        switch(name)
        {
            case "firstName": tem = "firstname";  break;
            case "lastName": tem = "lastname";  break;
            case "email": tem = "useremail";  break;
            case "secondaryEmail": tem = "secondary_email";  break;
            case "dropboxEmail": tem = "dropbox_email";  break;
            case "password": tem = "password";  break;
            case "referral": tem = "referral";  break;
            case "otherReferral": tem = "otherreferral";  break;
            case "videoType": tem = "video_type";  break;
            case "aboutVideo": tem = "about_video";  break;
            case "socialMediaLink": tem = "social_media_link";  break;
            case "videoStyle": tem = "video_style";  break;
            case "videoInspired": tem = "video_inspired";  break;
            case "videoInfo": tem = "video_info";  break;
            case "additionalVideoInfo": tem = "additional_video_info";  break;
            case "billingDate": tem = "billing_date";  break;
            case "preferredEditingSoftware": tem = "preferred_editing_software"; break;
            
            case "channelName": tem = "channel_name";  break;
            case "prevThumbnailsLink": tem = "prev_thumbnails_link";  break;
            case "refThumbnailsFile": tem = "ref_thumbnails_file";  break;
            case "thumbnailFont": tem = "thumbnail_font";  break;
            case "thumbnailMainColor": tem = "thumbnail_main_color";  break;
            case "thumbnailSubColor": tem = "thumbnail_sub_color";  break;
            case "descriptionLink": tem = "description_link";  break;
            case "photoPreference": tem = "photo_preference";  break;
            case "textPreference": tem = "text_preference";  break;
            case "titlePreference": tem = "title_preference";  break;
            case "endCardPreference": tem = "end_card_preference";  break;
            case "publicVideo": tem = "public_video";  break;
            case "keywordList": tem = "keyword_list";  break;
            case "additionalThumbnailData": tem = "additional_thumbnail_data";  break;
            default:
                break;
        }

        setChangedData(prev => ({...prev,[name]:null}));

        if((tem==="password" && value !== "")||( tem !== "password" && state.userProfile[tem] !== temValue))
        {
            setChangedData(prev=>({...prev, [name] : 1}));
        }
        // eslint-disable-next-line no-mixed-operators
        else if (((tem === "password" && value === "") || ( tem !== "password" && state.userProfile[tem] === temValue ) && changedData[name])){
            setChangedData(prev=>({...prev,[name]:null}))
        }

        setState(prev => ({...prev, [name]: value}));
        let ele = document.getElementById(`${name}-error-msg`);
        if(ele && ele.classList === "show-error") {
            ele.classList.remove("show-error");
            ele.classList.add("error-hidden");
        }
    }

    // commented this function so that update button does not depend on set password 
    // function validated() {
    //     return !(incompleteProfile && state.password === "");
    // }

    function onEmailPreferencesChange(e) {
        // eslint-disable-next-line no-unused-vars
        const {name, value} = e.target;
        let mailPreferences = state.mailPreferences ? state.mailPreferences : INITIAL_EMAIL_PREFERENCES_STATE;
        mailPreferences[name] = mailPreferences[name] === 1 ? 0:1;
        setState(prev => ({...prev, mailPreferences}));
    }

    async function updateProfileImage() {
        if (profileImage === state.userProfile.profile_img) {
            return;
        }
        try {
            let url = null;
            const progressBar = infoToast("Updating avatar, please wait", 0);
            if (profileImage) {
                url = await fetch(profileImage)
                    .then(r => r.blob())
                    .then(blob => uploadMedia(blob, loggedInUser.client_id, progressBar));

            }
            await axiosClient.post('/api/user/profile', {profile_img: url || ''});
            successToast("Avatar updated successfully");
            props.fetchUser();
        } catch(err) {
            console.error(err);
            errorToast("Something went wrong in uploading");
        }
    }

    function makeLinks(name, value, onRemove) {
        if(value.length)
        {
        let links = Array(value.length - 1);
        for(let id = 1; id < value.length; id++) {
            links[id-1] = (
                <TextInput key={`txt_${name}-${id}`} s={12} name={name} type="url" validate onChange={e => onChangeLinks(e, id)} value={value[id]}
                   icon={<Button style={{marginRight: "10px"}} className="btn-danger" onClick={() => onRemove(id)}><Icon>remove</Icon></Button>} />
            );
        }
        return links;
    }
    }

    function removePrevThumbnailsLink(id) {
        let list = state.prevThumbnailsLink;
        list.splice(id, 1);
        setState(prev => ({...prev, prevThumbnailsLink: list}));
    }
    
    function removeDescriptionLink(id) {
        let list = state.descriptionLink;
        list.splice(id, 1);
        setState(prev => ({...prev, descriptionLink: list}));
    }

    function onChangeLinks(e, index) {
        let name = e.target.name;
        let value = e.target.value;
        
        if(index != null && index >= 0) {
            let list = state[name];
            list = resize(list, index, "");
            list[index] = value;
            setState(prevState => ({...prevState, [name]: list}));
        }

        let ele = document.getElementById(`${name}-error-msg`);
        if(ele && ele.classList === "show-error") {
            ele.classList.remove("show-error");
            ele.classList.add("error-hidden");
        }
    }

    function addPrevThumbnailsLink() {
        setState(prev => ({...prev, prevThumbnailsLink: [...prev.prevThumbnailsLink, ""]}));
    }
    function addDescriptionLink() {
        setState(prev => ({...prev, descriptionLink: [...prev.descriptionLink, ""]}));
    }
    async function uploadFile() {
        try {
            if (selectedFile) {
                
                let url = null;
                const progressBar = infoToast("Uploading file, please wait", 0);
                
                if(state.refThumbnailsFile && state.refThumbnailsFile !== ""){
                    await axiosClient.post('/api/delete/media',{
                        url: state.refThumbnailsFile
                    })
                }
            
                url = await fetch(selectedFile)
                    .then(r => r.blob())
                    .then(blob => uploadMedia(blob, loggedInUser.client_id, progressBar));
                // eslint-disable-next-line no-useless-computed-key
                setState(prev => ({...prev, ["refThumbnailsFile"]: url}));

                await axiosClient.post('/api/user/uploadThumbnailFile', {
                    ref_thumbnails_file: url || '',
                    client_id:loggedInUser.client_id
                });

                successToast("File updated successfully");
                setShowThumbnailsPre(true);
                setSelectedFile(null);
            }
        } catch(err) {
            console.error(err);
            errorToast("Something went wrong in uploading");
        }
    }

    function handleSkipForNowBtn() {
        localStorage.setItem("skipProfileForNow",true);
        props.history.push('/');     
    }

    function handleActiveTab() {
        const tag = document.getElementsByClassName('active')[isMobile ? 2 : 1]?.innerText;
        setActiveTab(tag);
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (
        <Card className={`profile-cover ${customer ? styles.cardContainer : null}`}
            title={incompleteProfile ? "Complete your registration" :  "Your Profile"}
            actions={activeTab === "BASIC INFO" ? <div className={styles.buttonContainer}>
                <Button key="refresh-btn" onClick={refreshUser} node="button" waves="green" large flat>
                    Reload <Icon className={styles.deskModeInline} right>refresh</Icon>
                </Button>
                {/* // Removed || !validated() so that update button does not depend on set password or not  */}
                <Button key="update-btn" onClick={() => onClickUpdate()} disabled={updateDisabled}
                        node="button" waves="green" className="btn-primary" large>
                    Update <Icon className={styles.deskModeInline} right>save</Icon>
                </Button>
                {showYtField && !incompleteProfile ?
                <Button key="skip-for-now-btn" onClick={() => handleSkipForNowBtn()}
                        node="button" waves="green" className="btn-primary" large>
                    Skip
                </Button> : null}
                {
                    loggedInUser.client_type === CUSTOMER ?
                        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
                            <CustomLoginButton
                                showGoogleLogin={showGoogleLogin}
                                setShowGoogleLogin={setShowGoogleLogin}
                                setYTData={() => {}}
                                setIsLoading={false}
                                />
                        </GoogleOAuthProvider>
                        : null
                }
                </div> : null}
        >
            <Tabs className="user-tabs" options={{ responsiveThreshold: Infinity }} onChange={(e) => handleActiveTab(e.target)}>
                <Tab title="Basic Info" className="basicInfo">
                    <Container id="customer-tabs-container">
                        <Prompt 
                            when={Object.keys(changedData).find(key => changedData[key] === 1)?true:false}
                            message={()=>"Changes that you made may not be saved."}>
                        </Prompt>
                        <Row>
                            <Col className={styles.colStyle}>
                                <div className="profile-box">
                                    <div className={`left-box ${styles.imgAndBtnWrapper}`}>
                                    <label htmlFor="file-input">
                                        <img src={profileImage || Profile} alt="Avatar" style={{width: "100%", height: "auto", cursor: "pointer"}}/>
                                    </label>
                                    <input id="file-input" type="file" style={{display: "none"}} accept="image/*" onChange={e => setProfileImage(URL.createObjectURL(e.target.files[0]))}/>
                                    
                                    <Button onClick={() => setProfileImage(null)} className="btnStyle btn-danger" icon={<Icon className={styles.deskModeInline} left>clear</Icon>} style={{width: "150px"}} tooltip="Press Set Avatar afterwards to save changes">Clear</Button>
                                <Button onClick={updateProfileImage} className="btnStyle btn-primary" icon={<Icon className={styles.deskModeInline} left>save</Icon>} style={{width: "150px"}}
                                        tooltip="Save image as avatar">Set Avatar</Button>
                                    
                                    </div>
                                    <div className="right-box custom-input-profile">
                                    {
                            loggedInUser.is_temporary_password ?
                            <Row>
                                <Icon left>warning</Icon><h5 className="text-darken-1">Please set a new password</h5>
                            </Row>
                                : null
                        }
                                        {
                                            customer ? <Row className="profile-video-row">
                                                <h5 className="video_heading">How to use portal</h5>
                                                <div className="profile_outer_video_row">
                                                    {
                                                        state.showProtalVideo ? <div>
                                                            <Col style={{padding: 0}} s={12} m={12}>
                                                                <video id="profile-portal-vid" preload="metadata" controls>
                                                                    <source src={"https://vidchops-video.nyc3.cdn.digitaloceanspaces.com/773/how_portal_works_v.2-(1080p).mp4"} />
                                                                </video>
                                                            </Col>
                                                        </div>
                                                            : null
                                                    }
                                                </div>

                                                <Checkbox
                                                    name="showProtalVideo"
                                                    onChange={() => setState(prev => ({ ...prev, showProtalVideo: state.showProtalVideo === 0 ? 1 : 0 }))}
                                                    label="Hide video"
                                                    checked={!Boolean(state.showProtalVideo)}
                                                    value="1" />
                                            </Row> : null
                                        }
                        
                            <TextInput style={{padding: "0 !important"}} s={12} m={6}
                                    id={randomNumber()}
                                    password
                                    name="password"
                                    className={styles.textInput}
                                    label={"New Password" + (incompleteProfile ? " *" : "")}
                                    value={state.password}
                                    onChange={onChange}
                            />
                            {
                                customer ?
                                    <DatePicker s={12} m={6}
                                                disabled={true}
                                                options={{
                                                    format: "yyyy-mm-dd",
                                                    defaultDate: state.billingDate,
                                                    setDefaultDate: true
                                                }}
                                    /> : null
                            }
                            <TextInput s={12} m={6}
                                    id={randomNumber()}
                                    name="firstName"
                                    label="First Name *"
                                    className={styles.textInput}
                                    value={state.firstName}
                                    onChange={onChange}
                            />
                            <TextInput s={12} m={6}
                                    id={randomNumber()}
                                    name="lastName"
                                    label="Last Name *"
                                    className={styles.textInput}
                                    value={state.lastName}
                                    onChange={onChange}
                            />
                            {
                                customer ?
                                    <Select s={12} m={6}
                                            id={randomNumber()}
                                            name="referral"
                                            label="How did you find us? *"
                                            value={state.referral}
                                            onChange={onChange} >
                                        <option value="Google">Google</option>
                                        <option value="Youtube">Youtube</option>                              
                                        <option value="Facebook">Facebook</option>
                                        <option value="Affiliate">Affiliate</option>
                                        <option value="Other">Other</option>
                                    </Select> : null
                            }
                            {
                                customer && (state.referral === "Other" || state.referral === "Affiliate") ?
                                    <TextInput s={12} m={6}
                                            id={randomNumber()}
                                            name="otherReferral"
                                            label="How did you find us? *"
                                            className={styles.textInput}
                                            value={state.otherReferral}
                                            onChange={onChange}
                                    /> : null
                            }
                            <TextInput s={12} m={6}
                                    id={randomNumber()}
                                    email validate
                                    name="email"
                                    label="Your account login email (primary)? *"
                                    className={styles.textInput}
                                    value={state.email}
                                    onChange={onChange}
                            />
                            <TextInput s={12} m={6}
                                    id={randomNumber()}
                                    name="secondaryEmail"
                                    label="Any assistant's email address?"
                                    className={styles.textInput}
                                    placeholder="mail@example.com, mail1@example.com"
                                    value={state.secondaryEmail}
                                    onChange={onChange}
                            />
                            {/* Change from "What is your Dropbox email? *" */}
                            <TextInput s={12} m={6}
                                    id={randomNumber()}
                                    email validate
                                    name="dropboxEmail"
                                    label="What is your Gdrive Email? *"
                                    className={styles.textInput}
                                    value={state.dropboxEmail}
                                    onChange={onChange}
                            />
                            <Select s={12} m={6}
                                id={randomNumber()}
                                name="timezone"
                                label="Timezone"
                                value={state.timezone}
                                onChange={onChange} >
                                {
                                    TIMEZONE_LIST.map((item) => (
                                        <option value={item.value}>{item.label}</option>
                                    ))
                                }
                            </Select>
                            {
                                customer ? React.Children.toArray([
                                    <div className={styles.textInput}>
                                    <TextInput s={12} m={6}
                                            id={randomNumber()}
                                            name="videoType"
                                            label="What kind of video do you mostly deal with?"
                                            value={state.videoType}
                                            className={styles.textInput}
                                            onChange={onChange}
                                    />
                                    </div>,
                                    <div className={styles.textInput}>
                                    <Textarea s={12} m={6}
                                            id={randomNumber()}
                                            name="aboutVideo"
                                            label="Tell me more about your videos?"
                                            className={styles.textInput}
                                            value={state.aboutVideo}
                                            onChange={onChange}
                                    />
                                    </div>,
                                    <div className={styles.textInput}>
                                    <Textarea s={12} m={6}
                                            id={randomNumber()}
                                            name="socialMediaLink"
                                            label="What is your YouTube channel? What are your active social media accounts?"
                                            value={state.socialMediaLink}
                                            className={styles.textInput}
                                            onChange={onChange}
                                    />
                                    </div>,
                                    <div className={styles.textInput}>
                                    <Textarea s={12} m={6}
                                            id={randomNumber()}
                                            name="videoStyle"
                                            label="What is the basic format and style of your videos?"
                                            className={styles.textInput}
                                            value={state.videoStyle}
                                            onChange={onChange}
                                    />
                                    </div>,
                                    <div className={styles.textInput}>
                                    <Textarea s={12} m={6}
                                            id={randomNumber()}
                                            name="videoInspired"
                                            className={styles.textInput}
                                            label="Who or what videos inspire you or look do you really like?"
                                            value={state.videoInspired}
                                            onChange={onChange}
                                    />
                                    </div>,
                                    <div className={styles.textInput}>
                                    <Textarea s={12} m={6}
                                            id={randomNumber()}
                                            name="videoInfo"
                                            label="What else do you think we should know about your videos, your company, or how they should be edited?"
                                            value={state.videoInfo}
                                            onChange={onChange}
                                    />
                                    </div>,
                                    <div className={styles.textInput}>
                                    <Textarea s={12} m={6}
                                            id={randomNumber()}
                                            name="additionalVideoInfo"
                                            className={styles.textInput}
                                            label="Any other information would you like to give us?"
                                            value={state.additionalVideoInfo}
                                            onChange={onChange}
                                    />
                                    </div>,
                                    <div className={styles.selectInput}>
                                    <Select s={12} m={6}
                                            id={randomNumber()}
                                            name="preferredEditingSoftware"
                                            label="Which editing software would you like us to use?"
                                            value={state.preferredEditingSoftware}
                                            onChange={onChange} >
                                        <option value="" >Choose an option</option>
                                        <option value="NA">Not Applicable</option>
                                        <option value="FCPX">Final Cut Pro</option>
                                        <option value="Adobe Premier Pro">Adobe Premier Pro</option>
                                    </Select>
                                    </div>,
                                    <Col s={12} m={6} id={randomNumber()} className={styles.subtitlesCol}>
                                        <Checkbox disabled={true}
                                                name="subtitles"
                                                label="Subtitles plan"
                                                checked={Boolean(state.userProfile.has_subtitles) || false}
                                                value="1"/>
                                    </Col>,
                                    <Col s={12} m={6} id={randomNumber()} className={styles.ytHelperPlanCol}>
                                        <Checkbox disabled={true}
                                                name="hasYoutubeHelper"
                                                label="Youtube Helper plan"
                                                checked={Boolean(state.userProfile.has_youtube_helper) || false}
                                                value="1"/>
                                    </Col>,
                                    
                                    <Col className={`email_preferences_input ${styles.emailPref}`} style={{ marginTop: isMobile ? '20px' : '40px', marginBottom: isMobile ? 0 : '20px' }}>

                                        <label style={{
                                            fontSize: 'initial',
                                        }} >Email Preferences: </label>
                                        <br />
                                        <Checkbox
                                            id={CARD_MOVEMENT_EMAIL + "_checkbox"}
                                            name={CARD_MOVEMENT_EMAIL}
                                            label="Card Movement Email"
                                            onChange={(e) => onEmailPreferencesChange(e)}
                                            checked={Boolean(state.mailPreferences ? state.mailPreferences.card_movement : true)}
                                            value="1" />



                                        <Checkbox
                                            id={CARD_COMMENT_EMAIL + "_checkbox"}
                                            name={CARD_COMMENT_EMAIL}
                                            label="Card Comment Email"
                                            onChange={(e) => onEmailPreferencesChange(e)}
                                            checked={Boolean(state.mailPreferences ? state.mailPreferences.card_comment : true)}
                                            value="1" />

                                        <Checkbox
                                            id={CARD_COMMENT_TAG_EMAIL + "_checkbox"}
                                            name={CARD_COMMENT_TAG_EMAIL}
                                            label="Card Comment Tag Email"
                                            onChange={(e) => onEmailPreferencesChange(e)}
                                            checked={Boolean(state.mailPreferences ? state.mailPreferences.card_comment_tag : true)}
                                            value="1" />
                                    </Col>,

                                ]) : null
                            }
                            {
                                showYtField ? 
                                <Row>
                                    <Divider/>
                                    <Col s={12} id="yt-helper-field" className={styles.youtubeHelperHeading} >
                                        <h4>For YouTube Helper</h4>
                                    </Col>
                                    <Col s={12} className={styles.ytHelperMessage}>
                                        <h6 className="yt-helper-message">Please fill these fields for the YouTube Helper team assist you better.</h6>
                                    </Col>
                                    <Textarea s={12} m={6}
                                        id={randomNumber()}
                                        name="channelName"
                                        label="Is this an existing channel? If so, What is your channel's name? *"
                                        value={state.channelName}
                                        onChange={onChange}>
                                    <div id="channelName-error-msg" className="error-hidden">
                                        <MdErrorOutline style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' />
                                        Channel Name is required
                                    </div>
                                    </Textarea>
                                    <div className={`thumbnail_row ${styles.thumbnailWrapper}`}>
                                    {
                                        !showThumbnailsPre ?
                                        <div className={`thumbnails-file-col ${styles.deskMode}`}>
                                            <div className={`thumbnails-file-wrap ${styles.thumbnailFileWrap}`}>
                                                <label>
                                                    If you have a file you wish us to see as reference for your thumbnails, please upload here.
                                                </label>
                                                <TextInput 
                                                    type = "file" 
                                                    accept="image/*" 
                                                    onChange = {e => 
                                                        setSelectedFile(URL.createObjectURL(e.target.files[0]))} 
                                                    label="Select File" />
                                                <div className ="thumbnail-prev-btn"> 
                                                <Button onClick={()=>{setSelectedFile(null); }} icon={<Icon left>clear</Icon>}>Cancel</Button>
                                                <Button icon={<Icon left>save</Icon>} style={{ width:'122px' }} onClick={()=>uploadFile()} disabled={!selectedFile}>Save</Button>
                                                </div>
                                            </div>
                                        </div>:null
                                    }
                                    {
                                        showThumbnailsPre ?
                                            <div className={`thumbnails-file-col ${styles.deskMode}`}>
                                                <div className="thumbnail-file-wrap">
                                                    <label htmlFor="thumbnails-file-input">
                                                        <img src={selectedFile || state.refThumbnailsFile} style={{width: "100%", height: "auto", cursor: "pointer"}} alt="" />
                                                    </label>
                                                    <input id="thumbnails-file-input" type="file" style={{display: "none"}} accept="image/*" onChange={e => 
                                                    setSelectedFile(URL.createObjectURL(e.target.files[0]))}/>
                                                    <div className ="thumbnail-prev-btn"> 
                                                        <Button onClick={()=>{setSelectedFile(null)}} icon={<Icon left>clear</Icon>}>Clear</Button>
                                                        <Button icon={<Icon left>save</Icon>} onClick={()=>uploadFile()}>Update</Button>
                                                    </div>
                                                </div>
                                            </div>:null
                                    }
                                    <div className={`thumbnails_link_div ${styles.thumbnailLinks}`}>
                                        <label>Please provide links to videos that have thumbnails you like so we can refer to them.</label>
                                        <TextInput className="thumbnails_link_add_Btn" s={12} m={6}
                                            icon={<Button className="btn-primary"
                                                            onClick={()=>addPrevThumbnailsLink()}
                                                            ><Icon className={styles.addIcon}>add</Icon></Button>}
                                            name="prevThumbnailsLink" type="url" validate 
                                            // label="Please provide links to videos that have thumbnails you like so we can refer to them."
                                            onChange={e => onChangeLinks(e,0)} value={state.prevThumbnailsLink[0]}/>
                                        {makeLinks("prevThumbnailsLink", state.prevThumbnailsLink,removePrevThumbnailsLink )}
                                    </div>
                                    {
                                        !showThumbnailsPre ?
                                        <div className={`thumbnails-file-col ${styles.mobileMode} ${styles.thumbnailFile}`}>
                                            <div className={`thumbnails-file-wrap ${styles.thumbnailFileWrap}`}>
                                                <label>
                                                    If you have a file you wish us to see as reference for your thumbnails, please upload here.
                                                </label>
                                                <TextInput 
                                                    type = "file" 
                                                    accept="image/*" 
                                                    onChange = {e => 
                                                        setSelectedFile(URL.createObjectURL(e.target.files[0]))} 
                                                    label="Select File" />
                                                <div className ={`thumbnail-prev-btn ${styles.thumbnailPrevBtn}`}> 
                                                <Button onClick={()=>{setSelectedFile(null); }} icon={<Icon left>clear</Icon>}>Cancel</Button>
                                                <Button icon={<Icon left>save</Icon>} style={{ width:'122px' }} onClick={()=>uploadFile()} disabled={!selectedFile}>Save</Button>
                                                </div>
                                            </div>
                                        </div>:null
                                    }
                                    {
                                        showThumbnailsPre ?
                                            <div className={`thumbnails-file-col ${styles.mobileMode} ${styles.thumbnailFile}`}>
                                                <div className={`thumbnail-file-wrap ${styles.thumbnailFileWrap}`}>
                                                    <label htmlFor="thumbnails-file-input">
                                                        <img src={selectedFile || state.refThumbnailsFile} style={{width: "100%", height: "auto", cursor: "pointer"}} alt="" />
                                                    </label>
                                                    <input id="thumbnails-file-input" type="file" style={{display: "none"}} accept="image/*" onChange={e => 
                                                    setSelectedFile(URL.createObjectURL(e.target.files[0]))}/>
                                                    <div className ={`thumbnail-prev-btn ${styles.thumbnailPrevBtn}`}> 
                                                        <Button onClick={()=>{setSelectedFile(null)}} icon={<Icon left>clear</Icon>}>Clear</Button>
                                                        <Button icon={<Icon left>save</Icon>} onClick={()=>uploadFile()}>Update</Button>
                                                    </div>
                                                </div>
                                            </div>:null
                                    }
                                    </div>
                                    <Textarea s={12} m={6}
                                        id={randomNumber()}
                                        name="thumbnailFont"
                                        label="What font(s) would you like us to use in your thumbnails? *"
                                        value={state.thumbnailFont}
                                        onChange={onChange} validate
                                    >
                                        <div id="thumbnailFont-error-msg" className="error-hidden">
                                            <MdErrorOutline style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' />
                                            Thumbnail Font is required
                                        </div>
                                    </Textarea>
                                    <div className={styles.textInput}>
                                    <Textarea s={12} m={6}
                                        id={randomNumber()}
                                        name="thumbnailMainColor"
                                        label="Give us 2 main colors you like us to use in your thumbnails.
                                        (Hexacode of the actual color is preferred but not required.) *"
                                        value={state.thumbnailMainColor}
                                        onChange={onChange}
                                    >
                                        <div id="thumbnailMainColor-error-msg" className="error-hidden">
                                            <MdErrorOutline style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' />
                                            Thumbnail Color is required
                                        </div>
                                    </Textarea>
                                    </div>
                                    <div className={styles.textInput}>
                                    <Textarea s={12} m={6}
                                        id={randomNumber()}
                                        icon={<Icon>short_text</Icon>}
                                        name="videoPublishingSchedules"
                                        label="Please provide your video publishing schedules."
                                        placeholder="Eg. Every Thursday, 10:00 AM, EST"
                                        value={state.videoPublishingSchedules}
                                        onChange={onChange}
                                    />
                                    <Textarea s={12} m={6}
                                        id={randomNumber()}
                                        name="thumbnailSubColor"
                                        label="Give us 1 secondary color you like us to use in your thumbnails.
                                        (Hexacode of the actual color is preferred but not required.)"
                                        value={state.thumbnailSubColor}
                                        onChange={onChange}
                                    />
                                    </div>
                                    <div className={`yt-photo-preference-div ${styles.photoPreferenceStyle}`}>
                                    <div className={styles.selectInput}>
                                    <Select
                                            id={randomNumber()}
                                            name="photoPreference"
                                            label="Do you plan on providing us with photos to us or should we take screenshots from your video? *"
                                            value={state.photoPreference}
                                            onChange={onChange} >
                                        <option value="" >Choose an option</option>
                                        <option value="provide_photos">I will provide photos</option>
                                        <option value="take_screenshots">Take screenshots</option>
                                    </Select>
                                    </div>
                                    <div id="photoPreference-error-msg" className="error-hidden">
                                        <MdErrorOutline style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' />
                                        Photo Preference is required
                                    </div>
                                    </div>
                                    <div className={styles.selectInput}>
                                    <Select s={12} m={6}
                                            id={randomNumber()}
                                            name="textPreference"
                                            label="How would you like the text on your thumbnails to appear?"
                                            value={state.textPreference}
                                            onChange={onChange} >
                                        <option value="" >Choose an option</option>
                                        <option value="with_text">With text</option>
                                        <option value="without_any_text">Without any text</option>
                                        <option value="use_your_description">Use your description</option>
                                    </Select>
                                    </div>
                                    <div className={styles.selectInput}>
                                    <Select s={12} m={6}
                                            id={randomNumber()}
                                            name="titlePreference"
                                            label="How would you like your titles to appear?"
                                            value={state.titlePreference}
                                            onChange={onChange} >
                                        <option value="" >Choose an option</option>
                                        <option value="search_engine_optimised">Search engine optimised</option>
                                        <option value="clickbaity">Clickbaity</option>
                                        <option value="A_combo_of_both">A combo of both</option>
                                    </Select>
                                    </div>
                                    <div className={styles.selectInput}>
                                    <Select s={12} m={6}
                                            id={randomNumber()}
                                            name="endCardPreference"
                                            label="Do you need an end card?"
                                            value={state.endCardPreference}
                                            onChange={onChange} >
                                        <option value="" >Choose an option</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </Select>
                                    </div>
                                    <Textarea s={12} m={6}
                                        id={randomNumber()}
                                        name="keywordList"
                                        label="Do you have a list of keywords? If so, please share them."
                                        value={state.keywordList}
                                        onChange={onChange}
                                    />
                                    <div className={styles.selectInput}>
                                    <Select s={12} m={6}
                                            id={randomNumber()}
                                            name="publicVideo"
                                            label="Do you want us to automatically set your videos to public when we upload?"
                                            value={state.publicVideo}
                                            onChange={onChange} >
                                        <option value="">Choose an option</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </Select>
                                    </div>
                                    <Textarea s={12} m={6}
                                        id={randomNumber()}
                                        name="additionalThumbnailData"
                                        label="Anything else you'd like to add?"
                                        value={state.additionalThumbnailData}
                                        onChange={onChange}
                                    />
                                    {/* <div className="thumbnail_row">
                                    <div className="thumbnails_link_div"> */}
                                    <Col s={12} className={`description_wrap ${styles.descriptionWrap}`}>
                                        <label>Please provide link(s) to a sample description you like us to base our descriptions off of. You may provide the link to an actual video here. *</label>
                                        <TextInput className="description_link_add_Btn"
                                            icon={<Button  className="btn-primary"
                                                            onClick={()=>addDescriptionLink()}
                                                            ><Icon>add</Icon></Button>}
                                            name="descriptionLink" type="url" validate 
                                            // label="Please provide link(s) to a sample description you like us to base our descriptions off of. You may provide the link to an actual video here. *"
                                            onChange={e => onChangeLinks(e,0)} value={state.descriptionLink[0]}/>
                                        {makeLinks("descriptionLink", state.descriptionLink,removeDescriptionLink )}
                                    {/* </div>
                                    </div> */}
                                    <div id="descriptionLink-error-msg" className="error-hidden">
                                        <MdErrorOutline style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' />
                                        Description Link is required
                                    </div>
                                    </Col>

                                </Row> :null
                            }
                        
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Tab>
                <Tab title="Billing">
                    <BillingTable data={state.billingData || []} />
                </Tab>
                <Tab title="YT Analytics Data">
                    <YTAnalyticsTable
                        showGoogleLogin={showGoogleLogin}
                        setShowGoogleLogin={setShowGoogleLogin}
                        ytAnalyticsData={state.ytAnalyticsData || []}
                        loggedInUser={loggedInUser}
                        customerId={state.customerId}
                        />
                </Tab>
            </Tabs>
        </Card>
    );
});

function mapStateToProps({auth}) {
    return {auth};
}
