export const VIDEO_REQUEST = 'video_request'
export const ON_HOLD = 'on_hold'
export const EDITING = 'editing'
export const QA = 'qa'
export const DONE = 'done'
export const CANCELED = 'canceled'
export const ARCHIVED = 'archived'
export const COMPLETED = 'Completed'

export const ADMIN = 'admin';
export const TEAM_LEAD = 'team-lead';
export const YT_HELPER_LEAD = 'yt-helper-lead';
export const YT_HELPER = 'yt-helper';
export const QA_USER = 'qa';
export const CUSTOMER = 'customer';

export const SINGLE = 'Single';
export const WEEKLY = 'Weekly';
export const UNLIMITED = 'Unlimited';
export const DOUBLE = 'Double';
export const CUSTOM = 'Custom';
export const VIDCHOPS_WEEKLY = 'VidChops Weekly';
export const VIDCHOPS_PRO = 'VidChops Pro';

export const ACTIVE = 'Active';
export const INACTIVEFOR14DAYS = 'Inactive for 14 Days';
export const INACTIVEFOR30DAYS = 'Inactive for 30 Days';

export const YT_HELPER_ASSINED = 'assignedCard';
export const YT_HELPER_IN_PROGRESS = 'inProgress';
export const YT_HELPER_IN_DONE = 'inDone';
export const YT_HELPER_CANCELLED = 'cancelled';
export const YT_HELPER_COMPLETED = 'completed';

export const YT_HELPER_SECTION = 'ytHelperSection';

export const CARD_MOVEMENT_EMAIL = "card_movement";
export const CARD_COMMENT_EMAIL = "card_comment";
export const CARD_COMMENT_TAG_EMAIL = "card_comment_tag";


export const INITIAL_EMAIL_PREFERENCES_STATE = {
    [CARD_MOVEMENT_EMAIL] : 1,
    [CARD_COMMENT_EMAIL] : 1,
    [CARD_COMMENT_TAG_EMAIL] : 1,
}

export const PHILLIPINES_TIMEZONE = "Asia/Manila";

export const TIMEZONE_LIST = [
    { value: "none", label: "None" },
    { value: "US/Eastern", label: "Eastern", key: "EST" },
    { value: "US/Central", label: "Central", key: "CST" },
    { value: "US/Mountain", label: "Mountain", key: "UTC" },
    { value: "Asia/Kolkata", label: "India", key: "IST" },
    { value: "Asia/Karachi", label: "Pakistan", key: "PST" },
    { value: "Asia/Manila", label: "Phillipines", key: "PHT" },
]
