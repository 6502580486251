import React, { useEffect, useState } from 'react';
import { Layout, Typography, Card, Button, Empty, Spin } from 'antd';
import {Button as MaterialButton, Modal, TextInput, Checkbox, Icon, Col, Collapsible, CollapsibleItem } from 'react-materialize';
import { Spinner } from "react-activity";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './TabContentStyle.module.scss';
import { addHeaderOfTab, getAdminPermission, getTeamLeadPermission,
        getQAPermission, getYTHelperPermission, getCustomerPermission } from './Utilities/utils';
import { useSelector } from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { BsCheckAll } from  'react-icons/bs';
import Timer from "react-compound-timer";
import moment from 'moment';
import {RiStackFill} from 'react-icons/ri';
import { AiOutlinePlus } from  'react-icons/ai';
import { BiHourglass, BiTime } from  'react-icons/bi';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import './TabContentStyle.scss';
import ListItem from "./ListItem/ListItem";
import { ADMIN, CANCELED, CUSTOMER, DONE, EDITING, ON_HOLD,
        QA, QA_USER, TEAM_LEAD, VIDEO_REQUEST, YT_HELPER,
        YT_HELPER_ASSINED,
        YT_HELPER_CANCELLED,
        YT_HELPER_IN_DONE,
        YT_HELPER_IN_PROGRESS,
        YT_HELPER_LEAD, YT_HELPER_SECTION } from '../../../api/Constants';
import { errorToast, convertServerToLocalTime, isAdmin, isTerminal, parseSqlDateTime, isCustomer, resize, S3_BUCKET, myBucket, isMobile } from '../../../api/Util';
import { getCards } from "../../../services/cardsService";
import ActivityStatusModal from '../../common/ActivityStatusModal';
import { addCardTimeline, addRevision, decideTimeline, markAsDone, moveCard, moveCardSubStatus, moveToQa, moveYtCard, moveYtHelperCardInDone, moveYtHelperCardInProgress, updateSorting } from '../../../api/CardActions';
import ConfirmationDialog from '../../ConfirmationDialog';
import EnableRepurpose from '../../EnableRepurpose/RepurposeModal';
import KanbanFilters from '../../Home/KanbanView/KanbanViewContent/KanbanFilters';
import RatingModal from '../../common/RatingModal/RatingModal';

const ListViewContent = ({activeTab, cardData, setCardData, totalCards, setTotalCards, filterCards, setFilterCards, isLoading, setIsLoading, offset, setOffset, section, setActiveTab, setIsBtnDisabled}) => {
    const [cardActivityModal, setCardActivityModal] = useState(false);
    const [cardActivityData, setCardActivityData] = useState([]);
    const [cardRepurposeModal, setCardRepurposeModal] = useState(false);
    const [currentCardId, setCurrentCardId] = useState(null);
    const [currentCardRepurpose, setCurrentCardRepurpose] = useState(true);
    const [reviewCard, setReviewCard] = useState({ open: false });
    const [addTimeline, setAddTimeline] = useState({ open: false });
    const [confirmation, setConfirmation] = useState({ open: false });
    const [confirmationRepurpose, setConfirmationRepurpose] = useState({ open: false });
    const [doneLink, setDoneLink] = useState({ open: false });
    const [confirmToQA, setConfirmToQA] = useState({open:false});
    const [confirmToQAFromDone, setConfirmToQAFromDone] = useState({open:false});
    const [doneLinkUrl, setDoneLinkUrl] = useState("");
    const [doneLinkUrlArray, setDoneLinkUrlArray] = useState([]);
    const [repurposeDoneLink, setRepurposeDoneLink] = useState({ open: false });
    const [selectedFile, setSelectedFile] = useState(null);
    const [activityLoader, setActivityLoader] = useState(false);
    const [contentID, setContentID] = useState("");
    const [requiredContentId, setRequiredContentId] = useState(true);
    const [key, setKey] = useState(1);
    const [ratingsModalData, setRatingsModalData] = useState({});
    const [isRatingsModalOpen, setIsRatingsModalOpen] = useState(false);
    const [confirmationCardToCompleted, setConfirmationCardToCompleted] = useState({ open: false });
    const [moveCardInProgress, setMoveCardInProgress] = useState({open:false});
    const [ytHelperAssetsModal, setYtHelperAssetsModal] = useState({open:false});
    const [confirmationYtCard, setConfirmationYtCard] = useState({open:false});
    const [iconState, setIconState] = useState({
        up: false,
        down: true,
        reset: false,
    });
    const [thumbnailsLink, setThumbnailsLink] = useState("");
    const [snippetLink, setSnippetLink] = useState("");
    const [unlistedLink, setUnlistedLink] = useState("");
    const [videoDescription, setVideoDescriptions] = useState("");
    const [editorState, setEditorState] = useState();

    const auth = useSelector((state) => state.auth);
    const admin = isAdmin(auth.loggedInUser);
    const customer = isCustomer(auth.loggedInUser);
    const viewType = localStorage.getItem("viewType");
    const { Content } = Layout;

    useEffect(() => {
        setCardData([]);
        setTotalCards(0);
        setIsLoading(true);
        setOffset(0);
        showCards(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, filterCards])

    const showCards = async (offset, dataFilter = null) => {
        let obj = {
            offset : offset,
            limit:10,
            section : activeTab,
            card_name: filterCards.card_name || "",
            viewType,
        }

        if (filterCards.startDate && filterCards.endDate) {
            obj = {
                ...obj,
                startDate: filterCards.startDate,
                endDate: filterCards.endDate,
            }
        }
        if (dataFilter) {
            obj.dataFilter = dataFilter;
        }
        if (filterCards.view_as) {
            obj = {
                ...obj,
                view_as: filterCards.view_as,
            }
        }
        const res = await getCards(obj);
        if (activeTab === "all_tabs") {
            setIsBtnDisabled(false);
            setCardData(res.data);
            setIsLoading(false);
        } else {
            if (res.status) {
                if (offset > 0) {
                    setCardData([...cardData, ...res.data.cards]);
                } else {
                    setCardData(res.data.cards);
                }
                setTotalCards(res.data.count);
                setIsLoading(false);
            } else {
                setIsBtnDisabled(false);
                setCardData([]);
                setTotalCards(0);
                setIsLoading(false);
                errorToast(res.error);
            }
        }
    }

    const handleFilterClick = async (type) => {
        let dataFilter = null;
        if (type === 'down') {
            setIconState({ up: true, down: false, reset: true });
            dataFilter = 'down';
        } else if (type === 'up') {
            setIconState({ up: false, down: true, reset: true });
            dataFilter = 'up';
        } else {
            setIconState({ up: false, down: true, reset: false });
        }
        await showCards(0, dataFilter);
    }
    

    const handleShowMore = () => {
        setIsLoading(true);
        setOffset(offset + 1);
        showCards(offset + 1);
    }

    const refresh = () => {
        showCards(offset);
    }

    const mapIcons = (card) => {
        let ratingStar;
        let completeIcon;
        let repurposeRatingStar;
        let repurposeCompleteIcon;
        let revisionRepurposeIcon;
        let revisionIcon;
        let pausedIcon;
        let repurposeIcon;
        let ytCompleteIcon;
        let ytHelperRatingStar;

        if (section !== YT_HELPER_SECTION) {
            if (card.card_status === DONE) {
                let rating = card.doneCardRating ?
                    (<strong className="fa-layers-text">{card.doneCardRating}</strong>) :
                    (<FontAwesomeIcon icon="question" color="black" size="xs"/>);

                    ratingStar = (
                    <span className="fa-layers fa-fw fa-pull-right">
                        <FontAwesomeIcon icon="star" color="#FFD700"/>
                        {rating}
                    </span>
                )

                if (card.is_complete) {
                    completeIcon = (
                        <FontAwesomeIcon icon="check" color="green" pull="right"/>
                    );
                }

                if (card.is_repurpose_complete) {
                    let repurposeRating = card.repurposeCardRating ?
                        (<strong className="fa-layers-text">{card.repurposeCardRating}</strong>) :
                        (<FontAwesomeIcon icon="question" color="black" size="xs"/>);

                    repurposeRatingStar = (
                        <span className="fa-layers fa-fw fa-pull-right">
                            <FontAwesomeIcon icon="star" color="#FFD700"/>
                            {repurposeRating}
                        </span>
                    )

                    repurposeCompleteIcon = (
                        <BsCheckAll style={{ float:'right',color:'green', strokeWidth: '1px', width: '27px', height: '27px' }} />
                    );
                }
            }

            if (card?.yt_is_complete) {
                let ytHelperRating = card.ythelperCardRating ?
                        (<strong className={`fa-layers-text ${styles.ratingTextStyle}`}>{card.ythelperCardRating}</strong>) :
                        (<FontAwesomeIcon icon="question" color="black" size="xs"/>);

                        ytHelperRatingStar = (
                        <span className="fa-layers fa-fw fa-pull-right">
                            <FontAwesomeIcon icon="star" color="#ff4545"/>
                            {ytHelperRating}
                        </span>
                    )
            }
            if (card.repurpose_revisions > 0 && card.card_status === 'done' && (card.repurpose_status=== 'done' || card.repurpose_status === 'editing')) {
                revisionRepurposeIcon = (<FontAwesomeIcon icon="redo" color="purple" pull="right"/>)
            }
    
            if (card.revision_nr > 0 && !isTerminal(card.card_status)) {
                revisionIcon = (<FontAwesomeIcon icon="redo" color="red" pull="right"/>)
            }
        
            if (card.paused && !isTerminal(card.card_status)) {
                pausedIcon = (<FontAwesomeIcon icon="pause-circle" color="blue" pull="right"/>)
            }
            if (card.repurpose_aspect || card.repurpose_headline) {
                repurposeIcon = (<RiStackFill style={{ float : "right" }} color="red"/>);
            }

            if (card.yt_is_complete) {
                ytCompleteIcon = (
                    <FontAwesomeIcon icon="check" color="green" pull="right"/>
                );
            }
        }
        return {
            ratingStar,completeIcon, repurposeRatingStar,
            repurposeCompleteIcon, revisionRepurposeIcon,
            revisionIcon, pausedIcon, repurposeIcon, ytCompleteIcon, ytHelperRatingStar
        };
    }

    const getDueDateRow = (card, admin, view) => {
        if (card.card_status === DONE || card.card_status === VIDEO_REQUEST || card.paused) {
            return "";
        }
        let now = new Date().getTime();
        let expiredTime = parseSqlDateTime(convertServerToLocalTime(card.due_date)).getTime() - now;
        let expired = (now - parseSqlDateTime(convertServerToLocalTime(card.due_date)).getTime()) > 0;
    
    return [
                <div className={`request-card-line ${view}`} key="timer-row">
                  {admin && !isNaN(expiredTime) && (
                    <div className={styles.iconAndTextContainer}>
                        <BiHourglass className='icon' />
                        {expired ? (
                            <Typography.Text className={styles.greyFontColor}>
                                Expired
                            </Typography.Text>
                        ) : (
                            <Timer initialTime={expiredTime}>
                                <Timer.Days /> days&nbsp;
                                <Timer.Hours formatValue={val => `${`${val}`.padStart(2, "0")}:`}/>
                                <Timer.Minutes formatValue={val => `${val}`.padStart(2, "0")}/>
                            </Timer>
                        )}
                    </div>
                )}
                  {card?.due_date && moment(card.due_date).isValid() && (
                    <div className={`${styles.iconAndTextContainer} ${view}`}>
                      <AiOutlinePlus className='icon' />
                      <Typography.Text className={styles.greyFontColor}>
                        {moment(card?.convertedTime).format('lll')}
                      </Typography.Text>
                    </div>
                  )}
                </div>
              ];  
    }

    function getYTCardDueDateRow(card, customer, view) {
        if (card.ytHelper_card_status === YT_HELPER_IN_DONE || card.ytHelper_card_status == null ) {
            return "";
        }
        let now = new Date().getTime();
        let expiredTime = parseSqlDateTime(convertServerToLocalTime(card.yt_due_date)).getTime() - now;
        let expired = (now - parseSqlDateTime(convertServerToLocalTime(card.yt_due_date )).getTime()) > 0;
    
        return [
            <div className={`request-card-line ${view}`} key="timer-row">
                {
                    !customer ? <div className={styles.iconAndTextContainer}>
                                    <BiTime className='icon' />
                                    {
                                        expired ?
                                            <Typography.Text className={styles.greyFontColor}>
                                                Expired
                                            </Typography.Text> :
                                            <Timer initialTime={expiredTime}>
                                                <Timer.Days /> days&nbsp;
                                                <Timer.Hours formatValue={val => `${`${val}`.padStart(2, "0")}:`}/>
                                                <Timer.Minutes formatValue={val => `${val}`.padStart(2, "0")}/>
                                            </Timer>
                                    }
                                </div> : null
                }
            </div>,
            (card?.yt_due_date) &&
                <div className={`${styles.iconAndTextContainer} ${view}`}>
                    <AiOutlinePlus className='icon' />
                    <Typography.Text className={styles.greyFontColor}>
                        {moment(card?.convertedYTHelperTime).format('lll')}
                    </Typography.Text>
                </div>
        ];
    }

    function getData(tab) {
        let clientType = (auth.loggedInUser && auth.loggedInUser.client_type) || '';
        switch(clientType) {
            case ADMIN:
                return getAdminPermission(tab);
            case TEAM_LEAD:
            case YT_HELPER_LEAD:
                return getTeamLeadPermission(tab);
            case QA_USER:
                return getQAPermission(tab);
            case YT_HELPER:
                return getYTHelperPermission(tab);
            case CUSTOMER:
                return getCustomerPermission(tab);
            default:
                return '';
        }
    }

    const handleDropdownItemClick = (e, card, objKey) => {
        let tabStatus = activeTab === "all_tabs" ? objKey : activeTab;
        let targetStatus = e.key;
        let sourceStatus;
        let cardId = card.card_id;
        let qaAssignedTo = card?.qa_assigned_to;
        let revisions = card.revision_nr ? card.revision_nr : 0;
        let doneVideoLimit = card?.done_video_limit ? card.done_video_limit : "1";
        let is_complete = card.is_complete;
        let targetSubStatus;
        let sourceSubStatus = card.repurpose_status;
        let flag = false;

        if (viewType === "normal") {
            if (tabStatus === 'repurpose_editing') {
                sourceSubStatus = 'editing';
                sourceStatus = null;
            } else if (tabStatus === "repurpose_done") {
                sourceSubStatus = 'done';
                sourceStatus = null;
            } else if (tabStatus === "video_request" || tabStatus === "on_hold" || tabStatus === "editing" || tabStatus === "qa" || tabStatus === "done") {
                sourceStatus = activeTab;
                sourceSubStatus = null;
            }

            if (targetStatus === "repurpose_editing") {
                targetSubStatus = "editing";
                targetStatus = null;
            } else if (targetStatus === "repurpose_done") {
                targetSubStatus = "done";
                targetStatus = null;
            } else if (targetStatus === "video_request" || targetStatus === "on_hold" || targetStatus === "editing" || targetStatus === "qa" || targetStatus === "done") {
                targetStatus = targetStatus;
                targetSubStatus = null;
            }
            if (targetStatus === "completed_done" ) {
                setConfirmationCardToCompleted({open: true, cardId:cardId, qaAssignedTo: qaAssignedTo, cardStatus: sourceStatus});
             }

            if (targetSubStatus || sourceSubStatus) {
                if (admin && (!(targetSubStatus && sourceSubStatus) && !(targetStatus && sourceStatus) && !(targetSubStatus && sourceStatus))) {
                    flag = true;
                    switch (targetStatus) {
                        case VIDEO_REQUEST:
                            targetSubStatus = "video_request";
                            break;
                        case ON_HOLD:
                            targetSubStatus = "on_hold";
                            break;
                        case EDITING:
                            targetSubStatus = "normalEditing";
                            break;
                        case QA:
                            targetSubStatus = "normalQA";
                            break;
                        case DONE:
                            targetSubStatus = "normalDone"
                            break;
                        default:
                            break;
                    }

                    switch (targetSubStatus) {
                        case EDITING:
                            targetSubStatus = "repurposing";
                            break;
                        case DONE:
                            targetSubStatus = "repurposeDone";
                            break;
                        default:
                            break;
                    }

                    if (sourceSubStatus === "editing") {
                        sourceSubStatus = "repurposing";
                    } else if (sourceSubStatus === "done") {
                        sourceSubStatus = "repurposeDone";
                    }

                    if (sourceStatus === "editing") {
                        sourceStatus = "normalEditing";
                    } else if (sourceStatus === "done") {
                        sourceStatus = "normalDone";
                    } else if (sourceStatus === "qa") {
                        sourceStatus = "normalQA";
                    }
                    let state = {open: true, cardId, newStatus: targetSubStatus, cardStatus: sourceSubStatus || sourceStatus};
                    setConfirmation(state);
                } else {
                    let state = {open: true, cardId, newStatus: targetSubStatus, cardStatus: sourceSubStatus || sourceStatus};
                    if (targetSubStatus === DONE) {
                        setRepurposeDoneLink(state)
                    } else {
                        setConfirmationRepurpose(state);
                    }
                    return;
                }
            }
        }

        if (viewType === "yt-helper") {
            if (sourceStatus || targetStatus) {
                if (viewType === "yt-helper" && targetStatus === "assigned") {
                    targetStatus = "assignedCard";
                }
                // setting the new status and open Confirmation Dialog 
                let state = {open: true, cardId, newStatus: targetStatus, cardStatus: sourceStatus};
                if (targetStatus === YT_HELPER_IN_PROGRESS) {
                    setMoveCardInProgress(state);
                }
                else if (targetStatus === YT_HELPER_IN_DONE) {
                    setYtHelperAssetsModal(state);
                }
                else if (targetStatus === YT_HELPER_CANCELLED) {
                    setConfirmationYtCard(state);
                } else if (targetStatus === YT_HELPER_ASSINED && sourceStatus === YT_HELPER_CANCELLED) {
                    setConfirmationYtCard(state);
                }
                return;
            }
        }

        if (!customer && viewType === "normal" && !flag) {
            switch (targetStatus) {
                case VIDEO_REQUEST:
                    if (sourceStatus !== targetStatus && (is_complete !== '1' || admin)) {
                        setReviewCard({open: true, cardId, cardStatus: sourceStatus});
                    }
                    break;
                case EDITING:
                    if (is_complete !== '1' || admin) {
                        setAddTimeline({
                            open: true,
                            cardId,
                            cardStatus: sourceStatus,
                            timeline: decideTimeline(revisions)
                        });
                    }
                    break;
                case ON_HOLD:                    
                case CANCELED:
                    if (sourceStatus !== targetStatus && (is_complete !== '1' || admin)) {
                        setConfirmation({
                            open: true,
                            cardId,
                            newStatus: targetStatus,
                            cardStatus: sourceStatus
                        });
                    }
                    break;
                case DONE:
                    if (is_complete !== '1' || admin) {
                        setDoneLink({open: true, cardId, newStatus: DONE, cardStatus: sourceStatus, revisions,
                            doneVideoLimit : parseInt(doneVideoLimit)});
                    }
                    break;
                case QA:
                    if (is_complete !=='1' || admin) {
                        if (sourceStatus === DONE) {
                            setConfirmToQAFromDone({open: true,cardId,newStatus:QA,cardStatus:sourceStatus,
                                doneVideoLimit : parseInt(doneVideoLimit)});
                        } else {
                            setConfirmToQA({open: true,cardId,newStatus:QA,cardStatus:sourceStatus,
                                doneVideoLimit : parseInt(doneVideoLimit)});
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    };

    function isValidURL(string) {
        // eslint-disable-next-line no-useless-escape
        var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    };

    function onChangeContentIdCheck () {
        setRequiredContentId(!requiredContentId);
        setContentID("");
    }

    const handleCancelRatings = () => {
        setIsRatingsModalOpen(false);
    };
    /**
     * To save done url
     * @param {int} value 
     * @param {int} index 
     */
    function handleChangeDoneURL(value,index) {
        if (index != null && index >= 0) {
            let list = doneLinkUrlArray;
            list = resize(list, index, "");
            list[index] = value;
            setDoneLinkUrlArray(list);
        }
    }

    /**
     * To map the fields in modal
     * @param {int} limit 
     * @returns array of fields
     */
    function mapDoneVideoFields(limit) {
        let tempFields = [];
        for(let i = 0; i<limit-1; i++) {
            tempFields.push(
                <TextInput s={12} label={"Video Link"} icon="link" className="txt-rp-done-link-style" validate key={"qa-done-link" + i}
                value={doneLinkUrlArray[i] || ""} onChange={e => handleChangeDoneURL(e.target.value,i)}/>
            );
        }
        return tempFields;
    }

    const onChangeFile = (e) => {
        let value = e.target.files[0];
        setActivityLoader(true);
        const params = {
            ACL: 'public-read',
            Body: value,
            Bucket: S3_BUCKET,
            Key: 'prod/media/srt/'+value.name
        };
        myBucket.upload(params,
            (err, data) => {
            if (err) {
                return err;
            } else {
                let temp = data?.Key ? "https://vidchops-media.s3.amazonaws.com/"+ data.Key : null;
                setSelectedFile(temp);
            }
        })
    }

    useEffect(() => {
        if (selectedFile) {
            setActivityLoader(false);
        }
    }, [selectedFile, activityLoader])

    const handleOnDragEnd = (result) => {
        if (admin || customer) {
            if (!result.destination || (result.source.index === result.destination.index)) return;
            const items = Array.from(cardData);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            setCardData(items);
            let videoRequests = [];
            let sort = 1;
            for (let i = 0; i < items.length; i++) {
                let child = items[i];
                let childCardId = child.card_id;
                if (childCardId) {
                    videoRequests.push(
                        {"card_id": Number(childCardId), "sort": sort});
                    sort++;
                    if (childCardId === result.draggableId) {
                        return;
                    }
                }
            }
            updateSorting(videoRequests);
        } else {
            return;
        }
    }

    const isObject = (value) => {
        return (
          typeof value === 'object' &&
          value !== null &&
          !Array.isArray(value)
        );
    }

    function handleEditorStateChange(e) {
        if (e) {
            // Converting editor state to html 
            let value = draftToHtml(convertToRaw(e.getCurrentContent()));
            // Storing the editor state to display in the model
            setEditorState(e);
            // Storing the editor html tag in state so that it can be pass to backend
            setVideoDescriptions(value);
        }
    }

    return (
        <Content className={styles.content}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId='characters'>
                    {(provided) => (
                        <div className="characters" direction='vertical' style={{width: '100%'}} {...provided.droppableProps} ref={provided.innerRef}>
                          <Typography.Text strong className={`${styles.headingWithCount} tab-content-listview`}>
                                {activeTab !== "all_tabs" && `${addHeaderOfTab(activeTab)} (${totalCards})`}
                                <div class="customIcon"> {activeTab !== "all_tabs" && <KanbanFilters iconState={iconState} onFilterClick={handleFilterClick} section={activeTab} className="listViewFiltersIcon" />}</div>
                            </Typography.Text>
                            {
                            ((Array.isArray(cardData) && !cardData.length > 0)) && <Card className={styles.cards} loading={isLoading}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </Card>
                            }
                            {
                            (isObject(cardData) && !Object.keys(cardData).length > 0) && <Card className={styles.cards} loading={isLoading}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </Card>
                            }
                            {
                                isObject(cardData) && Object.keys(cardData)?.map(objKey => {
                                    // eslint-disable-next-line no-unused-expressions
                                    return <Collapsible accordion className={`cardListCollapsible ${styles.collapsibleStyle}`}>
                                                <CollapsibleItem expanded={false}
                                                    header={
                                                        <div className={styles.iconAndTitleDiv}>
                                                            <Typography.Text strong>{`${addHeaderOfTab(objKey)} (${cardData[objKey].count})` }</Typography.Text>
                                                            <span></span>
                                                        </div>
                                                    } node="div">
                                                    {cardData[objKey].array?.map((card, index) => {
                                                        return <>
                                                            <ListItem
                                                                card={card}
                                                                provided={provided}
                                                                mapIcons={mapIcons}
                                                                activeTab={activeTab}
                                                                section={section}
                                                                getDueDateRow={getDueDateRow}
                                                                getYTCardDueDateRow={getYTCardDueDateRow}
                                                                setCardActivityModal={setCardActivityModal}
                                                                setCardActivityData={setCardActivityData}
                                                                getData={getData}
                                                                handleDropdownItemClick={handleDropdownItemClick}
                                                                objKey={objKey} 
                                                                setCardRepurposeModal={setCardRepurposeModal}
                                                                setCurrentCardId={setCurrentCardId}
                                                                currentCardRepurpose={currentCardRepurpose} 
                                                                />
                                                                {index === cardData[objKey].array.length - 1 && cardData[objKey].count > 5 &&
                                                                    <div className={styles.showMoreBtnContainer}>
                                                                        <Button type="primary" onClick={() => setActiveTab(objKey)}>Show More</Button>
                                                                    </div>
                                                                }
                                                        </>
                                                })}
                                            </CollapsibleItem>
                                        </Collapsible>
                                })
                            }
                            {
                                Array.isArray(cardData) && cardData.map((card, index) => {
                                    return <Draggable isDragDisabled={activeTab !== VIDEO_REQUEST} key={card.card_id} draggableId={card.card_id.toString()} index={index}>
                                                {(provided) => (
                                                    <ListItem
                                                        card={card}
                                                        provided={provided}
                                                        mapIcons={mapIcons}
                                                        activeTab={activeTab}
                                                        section={section}
                                                        getDueDateRow={getDueDateRow}
                                                        getYTCardDueDateRow={getYTCardDueDateRow}
                                                        setCardActivityModal={setCardActivityModal}
                                                        setCardActivityData={setCardActivityData}
                                                        getData={getData}
                                                        handleDropdownItemClick={handleDropdownItemClick}
                                                        objKey={""}
                                                        setCardRepurposeModal={setCardRepurposeModal}
                                                        setCurrentCardId={setCurrentCardId}
                                                        currentCardRepurpose={currentCardRepurpose} />
                                                )}
                                            </Draggable>
                            })}
                            <ActivityStatusModal
                                cardActivityModal={cardActivityModal}
                                setCardActivityModal={setCardActivityModal}
                                cardActivityData={cardActivityData}
                            />
                            <EnableRepurpose
                                  cardRepurposeModal={cardRepurposeModal}
                                  setCardRepurposeModal={setCardRepurposeModal}
                                  currentCardId={currentCardId}
                                  setCurrentCardRepurpose={setCurrentCardRepurpose}
                            />
                            <ConfirmationDialog
                                onNegative={() => {setReviewCard({ open: false }); setKey(k => k+1);}}
                                onPositive={() => {addRevision(reviewCard.cardId, reviewCard.cardStatus, refresh); setReviewCard({ open: false })}}
                                confirmationHeader="Revision"
                                confirmationDialogId="confirm-revision"
                                confirmation={reviewCard.open}
                                confirmationText={"Are you sure you want to request revision?"}
                                />
                            <ConfirmationDialog
                                onNegative={() => {setAddTimeline({ open: false }); setKey(k => k+1);}}
                                onPositive={() => {addCardTimeline(addTimeline.cardId, addTimeline.cardStatus, addTimeline.timeline, refresh); setAddTimeline({ open: false })}}
                                confirmationHeader="Confirm"
                                confirmationDialogId={"confirm-timeline"}
                                confirmation={addTimeline.open}
                                confirmationText={"Confirm move card to editing?"}
                            />
                            <ConfirmationDialog
                                onNegative={() => {setConfirmation({open: false}); setKey(k => k+1);}}
                                onPositive={() => {moveCard(confirmation.cardId, confirmation.newStatus, confirmation.cardStatus, refresh); setConfirmation({open: false})}}
                                confirmationHeader="Confirm"
                                confirmationDialogId={"confirm-move"}
                                confirmation={confirmation.open}
                                confirmationText={"Are you sure you want to move the card?"}
                            />
                            <ConfirmationDialog
                                onNegative={() => {setConfirmToQAFromDone({open: false}); setKey(k => k+1);}}
                                onPositive={() => {moveCard(confirmToQAFromDone.cardId, confirmToQAFromDone.newStatus, confirmToQAFromDone.cardStatus, refresh); setConfirmToQAFromDone({open: false})}}
                                confirmationHeader="Confirm"
                                confirmationDialogId={"confirm-move"}
                                confirmation={confirmToQAFromDone.open}
                                confirmationText={"Are you sure you want to move the card?"}
                            />
                            <ConfirmationDialog
                                onNegative={() => {setConfirmationRepurpose({open: false}); setKey(k => k+1);}}
                                onPositive={() => {moveCardSubStatus(confirmationRepurpose.cardId, confirmationRepurpose.newStatus,
                                    confirmationRepurpose.cardStatus, refresh); setConfirmationRepurpose({open: false});}}
                                confirmationHeader="Confirm"
                                confirmationDialogId={"confirm-move-sub"}
                                confirmation={confirmationRepurpose.open}
                                confirmationText={"Are you sure you want to move the card to repurposing?"}
                            />
                            <ConfirmationDialog
                                onNegative={() => {setConfirmationCardToCompleted({open: false}); setKey(k => k+1);}}
                                onPositive={() => {
                                    setIsRatingsModalOpen(true);
                                    setRatingsModalData({
                                        cardId: confirmationCardToCompleted.cardId,
                                        qaAssignedTo: confirmationCardToCompleted.qaAssignedTo,
                                        cardStatus: confirmationCardToCompleted.cardStatus
                                    });
                                }}
                                confirmationHeader="Confirm"
                                confirmationDialogId={"confirm-move"}
                                confirmation={confirmationCardToCompleted.open}
                                confirmationText={"Are you sure you want to mark card as completed?"}
                            />
                            <Modal
                                actions={[
                                    <MaterialButton flat modal="close" node="button" waves="red" large>Close</MaterialButton>,
                                    <MaterialButton modal="close"
                                        onClick={() => markAsDone(doneLink.cardId, doneLink.cardStatus, doneLinkUrl, refresh, contentID,doneLinkUrlArray)}
                                        disabled={doneLinkUrl !== "" && ((requiredContentId && contentID !== "") || !requiredContentId) ? false : true} node="button" waves="green" large>Submit</MaterialButton>
                                ]}
                                header="Set video link and content id for music"
                                id="setVideoLinkModal"
                                className="cardMovementModal"
                                open={doneLink.open}
                                // style={{height: '35rem'}}
                                options={{
                                    dismissible: true,
                                    endingTop: '10%',
                                    opacity: 0.5,
                                    preventScrolling: true,
                                    onOpenStart: () => {setDoneLinkUrl(""); setContentID(""); setDoneLinkUrlArray([]); setRequiredContentId(true)},
                                    onCloseEnd: () => {setDoneLink({open: false}); setKey(k => k+1);}
                                }}
                            >
                                <TextInput s={12} label="Video Link" icon="link" validate id={"txt-done-link"}
                                        value={doneLinkUrl} onChange={e => setDoneLinkUrl(e.target.value)}/>
                                {
                                    doneLink?.doneVideoLimit > 1 ? mapDoneVideoFields(doneLink?.doneVideoLimit) : null
                                }
                                
                                <TextInput s={12} label={ doneLink.revisions > 0 ? "Any Additional Content ID (Optional)" : "Content ID for Music Licensing" } icon="short_text" id={"txt-content-id"}
                                        value={contentID} onChange={e => setContentID(e.target.value)} disabled={!requiredContentId} />
                                <Checkbox
                                    id="contentIdRequired"
                                    label="Please remove the checkmark If order has no Content ID"
                                    onChange={onChangeContentIdCheck}
                                    checked={requiredContentId}
                                    />
                            </Modal>
                            <Modal
                                actions={[
                                    <MaterialButton flat modal="close" node="button" waves="red" large>Close</MaterialButton>,
                                    <MaterialButton modal="close" tooltip={ activityLoader ? "Uploading File..." : null } onClick={() => moveCardSubStatus(repurposeDoneLink.cardId,
                                        repurposeDoneLink.newStatus, repurposeDoneLink.cardStatus, refresh, { done_link: doneLinkUrl, doneLinkUrlArray }, selectedFile)}
                                            disabled={!isValidURL(doneLinkUrl) || activityLoader ? true : false } 
                                            node="button" waves="green" large>Submit</MaterialButton>
                                ]}
                                header="Set repurposed video link"
                                id="setReVideoLinkModal"
                                className="cardMovementModal"
                                open={repurposeDoneLink.open}
                                // style={{height: '20rem'}}
                                options={{
                                    dismissible: true,
                                    endingTop: '10%',
                                    opacity: 0.5,
                                    preventScrolling: true,
                                    onOpenStart: () => { setDoneLinkUrl(""); setDoneLinkUrlArray([]); document.getElementById('srt-input').value = null; },
                                    onCloseEnd: () => {setRepurposeDoneLink({open: false}); setKey(k => k+1); document.getElementById('srt-input').value = null; setSelectedFile(null) }
                                }}
                            >
                                <TextInput s={12} label="Video Link" icon="link" className="txt-rp-done-link-style" validate id={"txt-rp-done-link"}
                                        value={doneLinkUrl} onChange={e => setDoneLinkUrl(e.target.value)}/>
                                {
                                    mapDoneVideoFields(4)
                                }
                                <div className={styles.srtFileWrapper} style={{ display: "flex" }}>
                                    <Icon style={{ marginRight: "18px", marginTop: "4px" }}>
                                        attach_file
                                    </Icon>
                                    <input
                                        style={{ width: "100%" }}
                                        className="custom-file-input"
                                        s={12}
                                        id="srt-input"
                                        type="file"
                                        name="srtFile"
                                        onChange={(e) => onChangeFile(e)}
                                        label="Select SRT File"
                                    />
                                    <Spinner color="#727981" size={20} speed={1} animating={activityLoader} />
                                </div>
                            </Modal>
                            <Modal
                                actions={[
                                    <MaterialButton flat modal="close" node="button" waves="red" large>Close</MaterialButton>,
                                    <MaterialButton modal="close"
                                        onClick={() => moveToQa(confirmToQA.cardId, confirmToQA.cardStatus, doneLinkUrl, refresh,doneLinkUrlArray)}
                                        disabled={!isValidURL(doneLinkUrl)} node="button" waves="green" large>Submit</MaterialButton>
                                ]}
                                header="Set video link"
                                id="setQAVideoLinkModal"
                                className="cardMovementModal"
                                open={confirmToQA.open}
                                // style={{height: '30rem'}}
                                options={{
                                    dismissible: true,
                                    endingTop: '10%',
                                    opacity: 0.5,
                                    preventScrolling: true,
                                    onOpenStart: () => {setDoneLinkUrl("");setDoneLinkUrlArray([])},
                                    onCloseEnd: () => {setConfirmToQA({open: false}); setKey(k => k+1);}
                                }}
                            >
                                <TextInput s={12} label="Video Link" icon="link" validate id={"qa-done-link"}
                                        value={doneLinkUrl} onChange={e => setDoneLinkUrl(e.target.value)}/>
                                {
                                    confirmToQA?.doneVideoLimit > 1 ? mapDoneVideoFields(confirmToQA?.doneVideoLimit) : null
                                }
                            </Modal>
                            <RatingModal 
                                isRatingsModalOpen={isRatingsModalOpen} 
                                handleCancelRatings={handleCancelRatings} 
                                data={ratingsModalData} 
                            />
                            <ConfirmationDialog
                                onNegative ={()=> {setMoveCardInProgress({open:false}); setKey(k=>k+1);}}
                                onPositive ={()=> {moveYtHelperCardInProgress(moveCardInProgress.cardId, moveCardInProgress.newStatus,moveCardInProgress.cardStatus,refresh)}}
                                confirmationHeader = "Confirm"
                                confirmationDialogId = {"confirm-ythelper-card-move"}
                                confirmation = {moveCardInProgress.open}
                                confirmationText = {"Are you sure you want to move the card for editing section"}
                            />
                            <ConfirmationDialog
                                onNegative={() => {setConfirmationYtCard({open: false}); setKey(k => k+1);}}
                                onPositive={() => moveYtCard(confirmationYtCard.cardId, confirmationYtCard.newStatus, confirmationYtCard.cardStatus, refresh)}
                                confirmationHeader="Confirm"
                                confirmationDialogId={"confirm-move"}
                                confirmation={confirmationYtCard.open}
                                confirmationText={"Are you sure you want to move the card?"}
                            />
                            <Modal
                                actions = {[
                                    <Button flat modal='close' className="close-btn" node= 'button' waves="red" large onClick={() => setYtHelperAssetsModal({open: false})}>Close</Button>,
                                    <Button modal="close" className="submit-btn" onClick={()=> {moveYtHelperCardInDone(ytHelperAssetsModal.cardId,
                                        ytHelperAssetsModal.newStatus,ytHelperAssetsModal.cardStatus,thumbnailsLink,videoDescription,snippetLink,unlistedLink,refresh
                                        ); setYtHelperAssetsModal({open: false})}} disabled={! (isValidURL(thumbnailsLink) && isValidURL(snippetLink) && (videoDescription !== "<p></p>")) } node="button" waves = "green" large>Submit</Button>
                                ]}
                                header = "Set YouTube Helper Assets"
                                id="setYtHelperAssetsModel"
                                open = {ytHelperAssetsModal.open}
                                style={{height: '50rem'}}
                                options={{
                                    dismissible: true,
                                    endingTop: '10%',
                                    opacity: 0.5,
                                    preventScrolling: true,
                                    onOpenStart: () => {setThumbnailsLink(""); setSnippetLink(""); setUnlistedLink(""); setVideoDescriptions("");setEditorState();},
                                    onCloseEnd: () => {setYtHelperAssetsModal({open: false}); setKey(k => k+1);}
                                }}
                            >
                                <TextInput s={12} label="Video Thumbnail Link" icon="link" validate id={"thumbnails-link"}
                                        value={thumbnailsLink} onChange={e => setThumbnailsLink(e.target.value)}/>

                                <TextInput s={12} label="Snippet Link" icon="link" validate id={"snippet-link"}
                                        value={snippetLink} onChange={e => setSnippetLink(e.target.value)}/>
                                
                                <TextInput s={12} label="Unlisted Video" icon="link" validate id={"unlisted-link"}
                                        value={unlistedLink} onChange={e => setUnlistedLink(e.target.value)}/>

                                <Col s={12} className="label-column">
                                <label>Video Description</label>
                                </Col>
                                <Col s={12} className="label-column labele_title">
                                    {!(isMobile) && <Icon>short_text</Icon>}
                                    <Editor
                                        toolbar = {{options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'emoji']}}
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e)=>{handleEditorStateChange(e)}}
                                    />
                                    <div className="clearfix"></div>
                                </Col>
                            </Modal>
                            <div className={styles.loader}>
                                <Spin size="large" spinning={isLoading} />
                            </div>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {
                (totalCards > (offset + 1) * 10) && !isLoading && activeTab !== "all_tabs" && 
                    <div className={styles.showMoreBtnContainer}>
                        <Button type="primary" onClick={() => handleShowMore()}>Show More</Button>
                    </div>
            }
        </Content>
    );
}

export default ListViewContent;
